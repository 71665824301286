"use client";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "src/components/atoms/button";
import { useAuthContext } from "src/context/auth-context";
import { useBioContext } from "src/context/bio-context";
import useLoadingError from "src/hooks/useLoadingError";
import {
  usePublishBioMutation,
  useUpdateUserBioMutation,
} from "src/services/users";
import { otherUserSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";
import { TOASTR_TYPES } from "src/types";
import { scrollToBioError, showToast } from "src/utils";

const PublishProfileComponent = () => {
  const [publishBio] = usePublishBioMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();
  const { user } = useAuthContext();
  const { formikBio } = useBioContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const otherUser = useAppSelector(otherUserSelector);

  const handleClick = () => {
    formikBio?.submitForm();

    if (!formikBio?.errors?.sections) {
      if (
        !otherUser?.first_name ||
        !otherUser?.last_name ||
        !otherUser?.profession
      ) {
        showToast(
          toast,
          TOASTR_TYPES.ERROR,
          "Please fill the required fields in personal info",
        );
        return;
      }

      startLoading();

      setTimeout(() => {
        publishBio({ id })
          .unwrap()
          .then((res: any) => {
            showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
            navigate(`/admin/users/${id}?activeTab=1`);
          })
          .catch((err: any) => {
            showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
          })
          .finally(() => {
            stopLoading();
          });
      }, 1500);
    } else {
      scrollToBioError();
    }
  };

  return (
    <>
      <CustomButton
        title="Publish"
        variant="custom"
        className="relative rounded-xl bg-white-button px-24 py-8 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none"
        onClick={handleClick}
        loading={loading}
      />
    </>
  );
};

export default PublishProfileComponent;
