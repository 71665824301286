import React from "react";
import CustomModal from "src/components/molecules/custom-modal";
import { IEditEmail } from "./types";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import SelectInput from "src/components/atoms/select-input";
import TextInput from "src/components/atoms/text-input";

const EditEmailComponent = ({
  toggle,
  isOpen,
  formik,
  loading,
}: IEditEmail) => {
  return (
    <CustomModal toggle={toggle} isOpen={isOpen} innerClass="xl:w-[580px]">
      <div
        className="modal-content flex w-full flex-col rounded-xl bg-gray-400"
        onKeyUp={(e) => {
          if (e.keyCode == 13) {
            formik?.handleSubmit();
          }
        }}
      >
        <div className="flex items-center justify-between gap-2 rounded-t-xl border-b border-border-color p-24">
          <span className="text-16 font-semibold leading-24 text-white-heading">
            Edit email
          </span>
          <button onClick={toggle}>
            <Icon.IcModalClose />
          </button>
        </div>

        <div className="modal-body p-24">
          <div className="flex flex-col gap-4">
            <TextInput
              name="email"
              type="text"
              label="Email address"
              value={formik?.values.email}
              showError={Boolean(formik?.touched.email)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.email}
              required={true}
            />
          </div>
        </div>

        <div className="flex flex-col justify-end gap-4 rounded-b-xl border-t border-border-color p-24 sm:flex-row">
          <CustomButton
            title="Cancel"
            variant="secondary"
            type="button"
            onClick={toggle}
          ></CustomButton>
          <CustomButton
            title="Save"
            variant="primary"
            type="submit"
            loading={loading}
            onClick={formik?.handleSubmit}
          ></CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditEmailComponent;
