// import Image from 'next/image'
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { countries } from "src/lib/country";
import PersonalDetailModal from "../personal-detail-modal";
import { Icon } from "src/components/atoms/icons";
import useModal from "src/hooks/useModal";
import LeaderBadge from "src/components/atoms/leader-badge";

const PersonalDetailComponent = ({ profile }: any) => {
  const { toggle, isOpen } = useModal();

  return (
    <>
      <div className="order-1 xl:order-3 xl:w-[30%]">
        <div className="flex-col rounded-lg border border-border-color sm:flex  sm:flex-row xl:mb-70 xl:flex-col">
          <div className="p-20 sm:w-1/2 xl:w-full">
            <div className="image-gradient-border relative mb-12 w-full">
              <img
                src={profile?.image || "/assets/images/Profile.svg"}
                alt="Featured Member Image"
                className={`${
                  profile?.image
                    ? "object-cover object-top"
                    : "object-cover object-center"
                }  h-full w-full cursor-pointer`}
                onClick={toggle}
              />
            </div>
            <h2 className="first-letter-capital flex items-center break-all text-20 font-semibold leading-32 tracking-0.4 text-white-heading">
              {profile?.name}
              {profile?.is_verify && (
                <span className="ml-4">
                  <Icon.IcVerified />
                </span>
              )}
            </h2>
            <p className="first-letter-capital text-14 font-medium leading-20 tracking-0.28 text-white-para">
              {profile?.profession}{" "}
              {profile?.office_name ? `@ ${profile?.office_name}` : ""}
            </p>
            {Boolean(profile?.content_leader) && (
              <div className="mt-4">
                <LeaderBadge />
              </div>
            )}
            {(profile?.linkedin_link || profile?.twitter_link) && (
              <div className="align-center mt-4 inline-flex flex-wrap gap-5 py-10 pr-10">
                {/* Place the linked in and twitter icons here */}
                {profile?.linkedin_link && (
                  <a
                    href={profile?.linkedin_link}
                    className="cursor-pointer transition duration-[0.4s] hover:scale-110"
                    target="_blank"
                  >
                    <Icon.IcLinkedinIcon />
                  </a>
                )}
                {profile?.twitter_link && (
                  <a
                    href={profile?.twitter_link}
                    className="cursor-pointer transition duration-[0.4s] hover:scale-110"
                    target="_blank"
                  >
                    <Icon.IcTwitterIcon />
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="border-t border-border-color p-20 sm:w-1/2 sm:border-l sm:border-t-0 xl:w-full xl:border-l-0 xl:border-t">
            <div className="mb-12 flex items-center gap-1">
              <h3 className="text-16 font-semibold leading-24 text-white-heading">
                Personal details
              </h3>
              {/* <EditDetail /> */}
              <button className="" onClick={toggle}>
                <Icon.IcPencil />
              </button>
              <PersonalDetailModal isOpen={isOpen} toggle={toggle} />
            </div>
            <div className="flex flex-col gap-3">
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Born
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {!profile?.city && !profile?.dob ? (
                    <p className="">&ndash;</p>
                  ) : (
                    ""
                  )}{" "}
                  {profile?.city}
                  {profile?.city && profile?.dob && <br></br>}
                  {profile?.dob && dayjs(profile?.dob).format("MMMM D, YYYY")}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Nationality
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {countries[profile?.country]?.name || (
                    <p className="">&ndash;</p>
                  )}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Profession
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {profile?.profession || <p className="">&ndash;</p>}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Education
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {profile?.education || <p className="">&ndash;</p>}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Office name
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {profile?.office_name || <p className="">&ndash;</p>}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Height
                </h4>
                <p className="first-letter-capital break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {(profile?.height ? profile?.height + " cm" : "") || (
                    <p className="">&ndash;</p>
                  )}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <h4 className="text-14 font-medium leading-20 text-white-heading">
                  Website
                </h4>
                <p className="break-words text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {(profile?.website ? (
                    <a
                      href={profile?.website}
                      target="_blank"
                      className="ellipsis-2 cursor-pointer text-14 font-normal leading-20 tracking-[-0.28px] text-blue-link"
                    >
                      {profile?.website}
                    </a>
                  ) : (
                    ""
                  )) || <p className="">&ndash;</p>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetailComponent;
