"use client";
import React, { useEffect, useRef, useState } from "react";
import Contents from "../../profile/contents";

import { POST_TYPES, TODO } from "src/types";
import "./post-view.css";
import PostHeader from "../post-header";
import { usePostsContext } from "src/context/posts-context";

import DescriptionView from "../../sections/description-view";

const PostViewComponent = ({ res, isPreview, isEditable }: TODO) => {
  const { formik } = usePostsContext();

  const lastSectionRef = useRef(null);
  const [sections, setSections] = useState([]);
  const [bio, setBio] = useState<any>([]);

  useEffect(() => {
    formik.setValues(
      isPreview
        ? {
            id: res?.data?.id,
            status: res?.data?.status,
            slug: res?.data?.slug,
            topic_id: res?.data?.topic_id,
            title: res?.data?.title,
            subtitle: res?.data?.subtitle,
            description: res?.data?.description,
            image: res?.data?.image,
            sections: res?.data?.content?.map(
              ({ id, title, description }: any) => ({
                id,
                title,
                description,
              }),
            ),
          }
        : {
            status: POST_TYPES.DRAFT,
            slug: "",
            topic_id: "",
            title: "",
            subtitle: "",
            description: "",
            image: "",
            sections: [
              {
                title: "",
                description: "",
                className: "add-section-min-height",
              },
            ],
          },
    );
  }, [isPreview, res]);

  useEffect(() => {
    setBio(res?.data?.content);
  }, [res?.data]);

  useEffect(() => {
    setSections(bio?.map((e: any, i: any) => i));
  }, [bio]);

  const [activeSection, setActiveSection] = useState(0);
  const handleScroll = () => {
    const headerOffset = 100;
    const navbarHeight = 50;
    for (let i = sections?.length - 1; i >= 0; i--) {
      const targetSection = document.getElementById(sections[i]);
      if (targetSection) {
        const sectionRect = targetSection.getBoundingClientRect();
        if (sectionRect.top - headerOffset <= navbarHeight) {
          setActiveSection(i);
          break;
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const handleSidebarItemClick = (index: number) => {
    const targetSection = document.getElementById(sections[index]);
    if (targetSection) {
      var headerOffset = 100;
      var elementPosition: any = targetSection?.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (!isMobile && lastSectionRef.current) {
      const lastSectionHeight: number = (
        lastSectionRef.current as HTMLDivElement
      ).offsetHeight;
      const marginBottom: number = Math.max(
        window.innerHeight - lastSectionHeight - 110,
        0,
      );

      (lastSectionRef.current as HTMLDivElement).style.marginBottom =
        `${marginBottom}px`;
    }
  }, [activeSection, lastSectionRef?.current, sections]);

  return (
    <div className="flex flex-col gap-7 p-30 xl:flex-row xl:gap-8">
      <div className="order-2 flex w-full gap-6">
        <Contents
          sections={bio}
          activeSection={activeSection}
          handleSidebarItemClick={handleSidebarItemClick}
        />

        <div className="order-3 w-full xl:order-2 xl:w-[90%]">
          <PostHeader
            post={res?.data}
            isPreview={isPreview}
            isEditable={isEditable}
          />

          {res?.data?.image && (
            <div className="mb-[30px] flex justify-center">
              <img
                src={res?.data?.image}
                alt="image"
                width={440}
                height={440}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                loading="eager"
                className="max-h-[500px] w-full object-contain md:w-[75%]"
              ></img>
            </div>
          )}

          <div className="mb-30 border border-transparent">
            <div className="relative flex items-center gap-1"></div>
            <div className="description-paragraph ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline text-14 font-normal leading-24 text-white-para ">
              {res?.data?.description}
            </div>
          </div>

          <div className="" id={`post-content-container`}>
            {bio?.map((section: any, i: number) => {
              return (
                <div
                  className="mb-30 border border-transparent"
                  id={sections && sections[i]}
                  key={i}
                  ref={i === sections?.length - 1 ? lastSectionRef : null}
                >
                  <div className="relative flex items-center gap-1">
                    <h2 className="text-18 font-semibold leading-28 text-white-heading">
                      {section?.title}
                    </h2>
                  </div>
                  <DescriptionView description={section?.description} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostViewComponent;
