import React from "react";
import { Icon } from "src/components/atoms/icons";
import { userAnalyticsSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";

const VisitorHighlightsComponent = () => {
  const userAnalytics = useAppSelector(userAnalyticsSelector);

  

  const data: any = [
    {
      value: "1.200",
      label: "Visitors",
      shift: "23.3%",
    },
    {
      value: "900",
      label: "Search appearances",
      shift: "23.3%",
    },
    {
      value: "2000",
      label: "Biography impressions",
      shift: "8.2%",
    },
  ];

  return (
    <div className="">
      <div className="text-white text-14 font-semibold leading-20 p-16 border-b border-solid border-border-color">
        Visitor highlights
      </div>
      <div className="px-24 py-16 grid sm:grid-cols-12 gap-4">
        {((userAnalytics?.highlights) || data)?.map((e: any) => {
          return (
            <div className="sm:col-span-4">
            <h6 className="text-white text-18 font-bold leading-28 tracking-0.36 mb-2">
              {e.value}
            </h6>
            <p className="text-white-para text-12 font-semibold leading-16 tracking-0.24 mb-8">
              {e.label}
            </p>
            {e.shift !== "0.00" && (
              <>
                {e.shift > 0 ? (
                  <p className="text-green text-12 font-semibold leading-16 tracking-0.24 flex items-baseline gap-0.5">
                    <Icon.IcGreenUpArrow />
                    {e.shift + "%"}
                  </p>
                ) : (
                  <p className="text-error-danger-500 text-12 font-semibold leading-16 tracking-0.24 flex items-center gap-0.5">
                    <Icon.IcRedDownArrow />
                    {e.shift + "%"}
                  </p>
                )}
              </>
            )}
          </div>
          
          
          );
        })}
      </div>
    </div>
  );
};

export default VisitorHighlightsComponent;
