import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import CreatePostForm from "src/components/organisms/posts/create-post-form";
import PostView from "src/components/organisms/posts/post-view";
import PreviewPostBtn from "src/components/organisms/posts/preview-post-btn";
import PublishPostBtn from "src/components/organisms/posts/publish-post-btn";
import SavePostBtn from "src/components/organisms/posts/save-post-btn";
import useQueryParams from "src/hooks/useQueryParams";
import { useGetPostMutation } from "src/services/posts";
import { userSelector } from "src/store/features/auth/selectors";
import { topicsSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";
import { PUBLISH_POST_BTN_TYPES } from "src/types";

const ViewPost = () => {
  const { postId, id: userId } = useParams();

  const [getPost, { data }] = useGetPostMutation();
  const { getQueryParam } = useQueryParams();

  const action = getQueryParam("action");
  useEffect(() => {
    getPost({ id: postId });
  }, []);

  console.log(data, "data>");

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      {action === "preview" && (
        <div className="sticky top-0 z-20 flex flex-wrap items-center justify-between gap-y-4 rounded-tl-lg  rounded-tr-lg bg-gray-400 p-16 ">
          <h3 className="text-24 font-bold leading-8 text-white-heading">
            Preview post
          </h3>
          <div className="relative flex gap-2">
            <Link
              to={`/admin/users/${userId}/post/${postId}/edit`}
              className="rounded-xl border border-white-button px-24 py-8 text-14 font-medium leading-20 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none"
            >
              Back to edit
            </Link>
            <PublishPostBtn buttonType={PUBLISH_POST_BTN_TYPES.DEFAULT} />
          </div>
        </div>
      )}
      <PostView res={data} isPreview={action === "preview"} isEditable={true} />
    </PanelLayout>
  );
};

export default ViewPost;
