"use client";

import { createContext, useContext, useState } from "react";
import { IPostsContext } from "./types";
import useModal from "src/hooks/useModal";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { POST_TYPES, PUBLISH_POST_BTN_TYPES, TOASTR_TYPES } from "src/types";

import useLoadingError from "src/hooks/useLoadingError";
import { useFormik } from "formik";
import { createPostSchema } from "src/validations/create-post-schema";

import { MUTATION_TYPE } from "src/hooks/types";
import { useNavigate } from "react-router-dom";
import useLoadMore from "src/hooks/useLoadMore";
import {
  useCreatePostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
} from "src/services/posts";
import { otherUserSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";

const PostsContext = createContext<IPostsContext>({
  isOpenConfirmDelete: false,
  loadingDeletePost: false,
  toggleConfirmDelete: () => null,
  handleDeletePost: () => null,
  setDeletePostId: (id: string | number) => null,
  myPosts: [],
  setMyPosts: null,
  loadMoreMyPosts: null,
  pageMyPosts: null,
  setPageMyPosts: null,
  totalPagesMyPosts: 0,
  loadingMyPosts: false,
  setFilter: POST_TYPES.ALL,
  fetchDataMyPosts: null,
  filter: null,
  formik: null,
  loadingPublishPost: false,
  isOpenBecomeMember: false,
  toggleBecomeMember: null,
  createUpdatePost: null,
  publishPostId: null,
  setPublishPostId: null,
  publishPostType: PUBLISH_POST_BTN_TYPES.DEFAULT,
  setPublishPostType: null,
  isOpenPublishPost: false,
  togglePublishPost: null,
});

export const PostsContextProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      status: POST_TYPES.DRAFT,
      slug: "",
      topic_id: "",
      title: "",
      subtitle: "",
      description: "",
      image: "",
      sections: [
        {
          title: "",
          description: "",
          className: "add-section-min-height",
        },
      ],
    },
    validationSchema: createPostSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {},
  });

  const [createPost] = useCreatePostMutation();
  const [updatePost] = useUpdatePostMutation();
  const [deletePost] = useDeletePostMutation();

  const [publishPostId, setPublishPostId] = useState();
  const [filter, setFilter] = useState<POST_TYPES>(POST_TYPES.ALL);

  const [publishPostType, setPublishPostType] =
    useState<PUBLISH_POST_BTN_TYPES>(PUBLISH_POST_BTN_TYPES.DEFAULT);

  // for deleting post
  const [deletePostId, setDeletePostId] = useState("");
  const {
    loading: loadingDeletePost,
    startLoading: startLoadingDeletePost,
    stopLoading: stopLoadingDeletePost,
  } = useLoadingError();

  // for publishing post
  const { loading: loadingPublishPost } = useLoadingError();
  const otherUser = useAppSelector(otherUserSelector);

  const {
    fetchData: fetchDataMyPosts,
    loading: loadingMyPosts,
    data: myPosts,
    setData: setMyPosts,
    loadMore: loadMoreMyPosts,
    page: pageMyPosts,
    setPage: setPageMyPosts,
    totalPages: totalPagesMyPosts,
  } = useLoadMore({
    initialData: [],
    startPage: null,
    filter,
    mutationType: MUTATION_TYPE.MY_POSTS,
    defaultLoading: true,
  });

  // for confirm delete modal
  const { isOpen: isOpenConfirmDelete, toggle: toggleConfirmDelete } =
    useModal();

  // for become a member modal
  const { isOpen: isOpenBecomeMember, toggle: toggleBecomeMember } = useModal();

  // for publish post modal
  const { isOpen: isOpenPublishPost, toggle: togglePublishPost } = useModal();

  const handleDeletePost = (userId: string) => {
    startLoadingDeletePost();
    deletePost({
      id: deletePostId,
    })
      .unwrap()
      .then((res: any) => {
        setMyPosts([]);
        setFilter(POST_TYPES.ALL);
        setPageMyPosts(2);
        fetchDataMyPosts({ page: 1, filter: POST_TYPES.ALL, userId });
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
      })
      .catch((err: any) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.message);
      })
      .finally(() => {
        navigate(`/admin/users/${userId}?activeTab=2`);
        stopLoadingDeletePost();
        toggleConfirmDelete();
      });
  };

  const createUpdatePost = (postStatus: POST_TYPES, userId: string) => {
    return new Promise((resolve, reject) => {
      const actionPayload = {
        ...formik?.values,
        user_id: userId,
        content: formik?.values.sections?.map((val: any, idx: any) => ({
          id: val?.id,
          title: val?.title,
          description: val?.description,
          sort_order: idx + 1,
          // user_id: otherUser?.id,
        })),
        status: postStatus,
      };
      console.log(actionPayload, "payload");
      const action = formik?.values?.slug ? updatePost : createPost; // Determine the action based on the presence of a slug

      action(actionPayload)
        .unwrap()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.message);
          reject(err);
        });
    });
  };

  return (
    <PostsContext.Provider
      value={{
        fetchDataMyPosts,
        isOpenConfirmDelete,
        loadingDeletePost,
        toggleConfirmDelete,
        handleDeletePost,
        setDeletePostId,
        myPosts,
        setMyPosts,
        loadMoreMyPosts,
        pageMyPosts,
        totalPagesMyPosts,
        loadingMyPosts,
        setFilter,
        filter,
        setPageMyPosts,
        formik,
        loadingPublishPost,
        isOpenBecomeMember,
        toggleBecomeMember,
        createUpdatePost,
        publishPostId,
        setPublishPostId,
        publishPostType,
        setPublishPostType,
        isOpenPublishPost,
        togglePublishPost,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};

export const usePostsContext = () => useContext(PostsContext);
