import React, { useEffect, useLayoutEffect, useState } from "react";
import BreadCrumbsCompoent from "./breadcrumbs.component";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";

const BradCrumbsContainer = (props: any) => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const pathnames = location.pathname.split("/").filter(Boolean);
  const [moduleName, setModuleName] = useState("");
  const [userName, setUsername] = useState("");
  const otherUser = useAppSelector(otherUserSelector);

  const labelMapping: { [key: string]: string } = {
    admin: "Home",
    users: "Users overview",
    user: "Users overview",
    "admin-users": "Admin users",
    "featured-members": "Featured members",
    settings: "Settings",
    create: "Create",
    "edit-bio": "Edit bio",
    "preview-bio": "Preview bio",
    "user-feedback": "User feedbacks",
    topics: "Add Topic",
    library: "Library",
    article: "Article",
  };
  const BreadCrumsHeader: { [key: string]: string } = {
    admin: "Home",
    users: "Users overview",
    user: "Users overview",
    "admin-users": "Admin users overview",
    "featured-members": "Featured members",
    settings: "Profile settings",
    create: "Create User",
    "user-feedback": "Feedbacks overview",
    topics: "Add Topic",
    library: "Library overview",
    article: "Create an article",
    // "534":'Addidas'
  };

  useLayoutEffect(() => {
    if (otherUser.name) {
      setUsername(otherUser.name);
    }
  }, [otherUser]);

  useLayoutEffect(() => {
    let lastSegmentLabel = BreadCrumsHeader[pathnames[pathnames.length - 1]];
    const breadcrumbItems: any[] = pathnames?.map((pathname, index): any => {
      const url = `/${pathnames.slice(0, index + 1).join("/")}`;
      const label = labelMapping[pathname] || pathname;
      return {
        label,
        url,
      };
    });

    breadcrumbItems.forEach((item, index) => {
      if (!isNaN(Number(pathnames[index]))) {
        breadcrumbItems[index].label = "Details";

        breadcrumbItems[index].url = `/${pathnames.slice(0, index).join("/")}`;
      }
    });

    if (pathnames.includes("user-feedback") && pathnames.length === 2) {
      lastSegmentLabel = "User Feedback List";
    }

    if (pathnames.includes("post") && pathnames.length === 4) {
      lastSegmentLabel = "Create a post";
    }

    if (pathnames.includes("post") && pathnames.length === 5) {
      lastSegmentLabel = "Preview post";
    }
    if (pathnames.includes("edit") && pathnames.length === 6) {
      lastSegmentLabel = "Edit post";
    }

    console.log(lastSegmentLabel, "===lastSegmentLabel");
    console.log(pathnames, "===lastSegmentLabel");

    // alert(pathnames.length);

    if (!lastSegmentLabel) lastSegmentLabel = userName || "";
    if (pathnames.includes("user-feedback") && pathnames.length === 3) {
      lastSegmentLabel = "Details";
    } else if (pathnames.includes("user-feedback") && pathnames.length === 2) {
      lastSegmentLabel = "Feedbacks overview";
    } else if (pathnames.includes("topics") && pathnames.length === 3) {
      lastSegmentLabel = "Details";
    } else if (pathnames.includes("article") && pathnames.length === 4) {
      lastSegmentLabel = "Preview an Article";
    } else if (pathnames.includes("article") && pathnames.length === 5) {
      lastSegmentLabel = "Edit an Article";
    }

    setModuleName(lastSegmentLabel || "");

    setBreadcrumbs(breadcrumbItems);
  }, [location, userName]);

  return (
    <BreadCrumbsCompoent
      {...props}
      breadcrumbs={breadcrumbs}
      moduleName={moduleName}
    />
  );
};

export default BradCrumbsContainer;
