"use client";
import React, { useEffect, useState } from "react";

import CustomButton from "src/components/atoms/button";

import { Icon } from "src/components/atoms/icons";
import { useAuthContext } from "src/context/auth-context";
import useLoadingError from "src/hooks/useLoadingError";
import { useShareContext } from "src/context/share-context";
import {
  SHARE_PLATFORM,
  SHARE_TYPE,
} from "src/components/molecules/share-button/types";

import CustomModal from "src/components/molecules/custom-modal";
import ShareCard from "../profile/share-card";

const LOGOS: any = {
  [SHARE_PLATFORM.FB]: <Icon.IcFacebook height={40} width={40} />,
  [SHARE_PLATFORM.TWITTER]: <Icon.IcTwitter height={40} width={40} />,
  [SHARE_PLATFORM.LINKED_IN]: <Icon.IcLinkedin height={40} width={40} />,
};

const ShareModalComponent = () => {
  const { user } = useAuthContext();
  const { isOpen, toggle, shareData } = useShareContext();
  const { loading } = useLoadingError();

  const [showImage, setShowImage] = useState(true);

  const handleDownload = (url: string, filename: string) => {
    // Fetch the image
    fetch(`${process.env.REACT_APP_API_URL}/api/download?url=${url}`)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new URL for the blob
        const href = URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = href;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(href);
      })
      .catch(console.error);
  };

  useEffect(() => {
    var timeout = setTimeout(() => {
      if (!isOpen) {
        setShowImage(false);
      } else {
        setShowImage(true);
      }
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <CustomModal
      isOpen={isOpen}
      openModal={toggle}
      toggle={toggle}
      innerClass="sm:w-[412px]"
    >
      <div className="relative flex w-full flex-col gap-4  rounded-[10px] border-border-color bg-gray-400 p-24">
        <button className="absolute right-[10px] top-[10px]" onClick={toggle}>
          <Icon.IcModalClose />
        </button>
        <div className="flex items-center gap-2 text-24 font-bold leading-[44px] text-white-heading">
          {LOGOS[shareData?.platform]}
          <span>Share on {shareData?.platform}</span>
        </div>
        <div className="mx-auto">
          <div className="relative">
            {showImage ? (
              <img
                src={showImage ? shareData?.imageLink || "" : ""}
                height={235}
                width={235}
                alt="image"
              ></img>
            ) : (
              <div className="h-[235px] w-[235px] bg-transparent"></div>
            )}

            <button
              className="absolute right-[7px] top-[7px]"
              onClick={() => handleDownload(shareData?.imageLink, "image.png")}
            >
              <Icon.IcDownload />
            </button>
          </div>
        </div>
        <div className="text-white">
          <ShareCard />
        </div>
        <div className="">
          <div className="flex flex-col gap-3">
            <CustomButton
              title={`Continue to ${shareData?.platform}`}
              variant="primary"
              onClick={() => {
                window.location.href = shareData.platformLink;
              }}
              loading={loading}
            />
            <CustomButton
              title={"Cancel"}
              variant="secondary"
              className="border-none"
              onClick={toggle}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ShareModalComponent;
