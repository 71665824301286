import { IResponse } from "../types";
import { onQueryStarted } from "../api.config";
import store from "src/store";
import { IPaginationPayload } from "../types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const featuredMembersApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminMembersListing: build.mutation<
      IResponse,
      Partial<IPaginationPayload>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_MEMBERS.GET_ADMIN_MEMBERS}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    addAdminUser: build.mutation<IResponse, Partial<{ id: string | number }>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_MEMBERS.ADD_ADMIN_USER}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    editAdminUser: build.mutation<IResponse, Partial<{ id: string | number }>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_MEMBERS.EDIT_ADMIN_USER}?user_id=${body?.id}`,
        body,
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    deleteAdminUser: build.mutation<IResponse, Partial<{ id: any }>>({
      query: (body) => ({
        url: `${ENDPOINTS.ADMIN_MEMBERS.DELETE_ADMIN_USER}?id=${body?.id}`,
        body,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
  }),
});
export const {
  useGetAdminMembersListingMutation,
  useAddAdminUserMutation,
  useEditAdminUserMutation,
  useDeleteAdminUserMutation,
} = featuredMembersApi;
