import React, { useEffect } from 'react'
import VisitorHighlights from '../visitor-highlights'
import VisitorCard from '../visitor-card'
import { useAppSelector } from 'src/store/hook'
import { userAnalyticsSelector } from 'src/store/features/user/selectors'

const UserAnalyticsComponent = () => {

  const userAnalytics = useAppSelector(userAnalyticsSelector);
  const data = [
    {
      title: "Visitor demographics",
      data: [
        {
          name: "Netherlands",
          value: 25,
          label: "300 (25%)",
        },
        {
          name: "France",
          value: 32,
          label: "384 (32%)",
        },
          {
            name: "Germany",
            value: 38,
            label: "456 (38%)",
          },
          {
            name: "Belgium",
            value: 25,
            label: "300 (25%)",
          },
      ],
    },
    {
      title: "Visitor age ranges",
      data: [
        {
          name: "< 20",
          value: "25",
          label: "300 (25%)",
        },
        {
          name: "21 - 30",
          value: "38",
          label: "456 (38%)",
        },
        {
          name: "31 - 40",
          value: "32",
          label: "384 (32%)",
        },
        {
          name: "> 41",
          value: "25",
          label: "300 (25%)",
        },
      ],
    },
    {
      title: "Visitor occupations",
      data: [
        {
          name: "Business and Finance",
          value: "25",
          label: "300 (25%)",
        },
        {
          name: "Education",
          value: "32",
          label: "384 (32%)",
        },
        {
          name: "Enginering",
          value: "25",
          label: "300 (25%)",
        },
        {
          name: "Information Technology",
          value: "38",
          label: "456 (38%)",
        },
      ],
    },
  ];


  return (
    <div className="bg-gray-500">
    <div className="bg-gray-300 rounded-b-xl">
        <VisitorHighlights/>
    </div>
    <div className="grid xl:grid-cols-12 gap-[22px] mt-24">
      {
        (userAnalytics?.cards)?.map((d:any) => {
           return <VisitorCard title={d?.title} data={d?.data}/>
        })
      }
    </div>
    </div>
  )
}

export default UserAnalyticsComponent