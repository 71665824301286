import React, { useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import CustomButton from "src/components/atoms/button";
import {
  useDashboardStatsMutation,
  useDeleteUserMutation,
  useGetUsersListingMutation,
} from "src/services/users";
import { useNavigate } from "react-router-dom";
import {
  useDeleteArticleMutation,
  useGetAllArticlesMutation,
} from "src/services/articles";

const DataTableContainer = () => {
  const [getAllArticles] = useGetAllArticlesMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({ key: "articles-datatable", mutation: getAllArticles });

  const [getDashboardStats] = useDashboardStatsMutation();

  const navigate = useNavigate();

  // for delete popup
  const { isOpen, toggle } = useModal();

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteArticle] = useDeleteArticleMutation();
  // const [getMembersListing, {data : usersData}] =  useGetMembersListingMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setDeleteId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteArticle({ id: deleteId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
        console.log(res, "response comes herer");
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        console.log(err, "error comes here");
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onRowClicked = (row: any) => {
    sessionStorage.removeItem("user-tabs");
    navigate(`/admin/library/article/${row?.id}`);
  };

  return (
    <>
      <DataTableComponent
        title={"Library"}
        description={`${pagination?.total == 0 ? "No articles" : pagination?.total > 1 ? `Total ${pagination?.total} articles` : `Total ${pagination?.total} articles`} `}
        className="date-picker"
        data={data}
        columns={columns(showDeletePopup)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={onRowClicked}
        clearSearch={clearSearch}
        button={
          <CustomButton
            title="Add article"
            variant="primary"
            onClick={() => {
              navigate("/admin/library/article");
            }}
          />
        }
      />

      <ConfirmDelete
        title="Are you sure you want to remove this article?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />
    </>
  );
};

export default DataTableContainer;
