import React, { useEffect, useState } from "react";
import { getCroppedImg } from "src/components/atoms/image-upload/canvas-utils";
import Cropper from "react-easy-crop";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import { Slider } from "antd";
import { useUploadFileMutation } from "src/services/auth";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import imageCompression from "browser-image-compression";

import "./crop-modal-easy.css";

const CropModalEasyComponent = (props: any) => {
  const { crop, setCrop, setImageName } = props;
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadFile] = useUploadFileMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const [originalAspectRatio, setOriginalAspectRatio] = useState(1 / 1);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<any>();

  const aspectRatios: any = {
    Full: originalAspectRatio,
    "1:1": 1 / 1,
    "4:3": 4 / 3,
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      startLoading();
      const croppedImage: any = await getCroppedImg(
        props?.src,
        croppedAreaPixels,
        rotation,
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);

      const res = await fetch(croppedImage);
      const blob = await res.blob();

      // Get the size of the blob in bytes
      const sizeInBytes = blob.size;

      // Convert bytes to megabytes
      const sizeInMegabytes = sizeInBytes / (1024 * 1024);

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const uniqueFileName = `croppedImage_${Date.now()}.jpg`;
      const file = new File([blob], uniqueFileName, { type: blob.type });

      const compressedFile = await imageCompression(file, options);

      const response: any = await uploadFile({
        image: compressedFile,
      }).unwrap();

      const imageName = response?.data?.image_name;
      setImageName(imageName);
      props?.setImage(imageName);
      props.onChange && props.onChange(imageName);
      setCrop({ x: 0, y: 0 });
      props.toggle();
    } catch (err: any) {
      showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    // if (selectedAspectRatio == "Full") {
    setZoom(1);
    // } else {
    //   setZoom(2);
    // }
  }, [selectedAspectRatio]);

  return (
    <div className="w-full rounded-xl border border-border-color bg-gray-400">
      <div className="flex  items-center justify-between border-b border-border-color p-24">
        <p className="text-16 font-semibold leading-24 text-white-heading">
          Crop Image
        </p>
        <button className="" onClick={props.toggle}>
          <Icon.IcModalClose />
        </button>
      </div>
      <div className="relative h-[400px] w-full">
        <Cropper
          image={props?.src}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatios[selectedAspectRatio]}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={(mediaSize) => {
            setOriginalAspectRatio(mediaSize.width / mediaSize.height);
            if (props.disableAspectRatio) {
              setSelectedAspectRatio(props.defaultAspectRatio || "1:1");
            } else {
              setSelectedAspectRatio("Full");
            }
          }}
        />
      </div>
      {!props.disableAspectRatio && (
        <div className="mt-12 flex justify-center gap-x-4">
          {props.hideButton !== true && (
            <>
              {Object.keys(aspectRatios).map((item: any, i: any) => {
                return (
                  <button
                    key={i}
                    className={`${selectedAspectRatio == item ? "border border-transparent bg-white-button text-gray-500" : "border border-white text-white"} rounded-xl  px-24 py-9 text-14 font-medium leading-20  transition duration-[0.4s]  focus:outline-none`}
                    onClick={() => {
                      setSelectedAspectRatio(item);
                    }}
                  >
                    {item}
                  </button>
                );
              })}
            </>
          )}
        </div>
      )}
      <div className="flex items-center justify-between px-24 py-12 ">
        <label className="text-14 font-medium leading-20 text-white-heading">
          Zoom
        </label>
        <Slider
          defaultValue={30}
          value={zoom}
          onChange={(value: any) => setZoom(value)}
          min={1}
          max={3}
          step={0.1}
          className="w-[200px]"
          tooltip={{ formatter: null }}
        />

        <CustomButton
          title="Crop"
          onClick={showCroppedImage}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CropModalEasyComponent;
