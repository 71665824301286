"use client";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "src/components/atoms/icons";
import { Dropdown } from "antd";
import useDropdownToggle from "src/hooks/useCustomDropdown";
import ShareTwitter from "../share-twiiiter";
import ShareFb from "../share-fb";
import ShareLinkedin from "../share-linkedin";
import CopyButton from "../copy-button";
import copy from "clipboard-copy";
import { IShareButton, SHARE_PLATFORM, SHARE_TYPE } from "./types";
import { useShareContext } from "src/context/share-context";
import { makeShareUrl } from "src/helpers";
import type { MenuProps } from "antd";
import "./style.css";

const ShareButtonComponent = ({
  shareType,
  slug,
  image,
  className,
  username,
}: IShareButton) => {
  const { isOpen, toggleDropdown, closeDropdown } = useDropdownToggle(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const {
    isOpen: isOpenShareModal,
    toggle: toggleShareModal,
    setShareData,
  } = useShareContext();

  const linkToShare = makeShareUrl(slug, shareType);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopyClick = async () => {
    await copy(linkToShare);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleShareLink = (sharePlatform: SHARE_PLATFORM) => {
    let platformLink = ``;
    switch (sharePlatform) {
      case SHARE_PLATFORM.FB:
        platformLink = `https://www.facebook.com/sharer.php?u=${linkToShare}`;

        break;

      case SHARE_PLATFORM.TWITTER:
        platformLink = `https://twitter.com/intent/tweet?url=${linkToShare}`;
        break;

      case SHARE_PLATFORM.LINKED_IN:
        platformLink = `https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`;
        break;
    }

    setShareData({
      platform: sharePlatform,
      imageLink: image,
      url: linkToShare,
      platformLink: platformLink,
      shareType,
      slug,
      username,
    });
    toggleShareModal();
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="border-b border-border-color  pt-8">
          <CopyButton handleCopyClick={handleCopyClick} isCopied={isCopied} />
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <ShareTwitter
          shareType={shareType}
          url={linkToShare}
          handleShareLink={handleShareLink}
        />
      ),
      key: "0",
    },
    {
      label: (
        <ShareFb
          shareType={shareType}
          url={linkToShare}
          handleShareLink={handleShareLink}
        />
      ),
      key: "1",
    },
    {
      label: (
        <ShareLinkedin
          shareType={shareType}
          url={linkToShare}
          handleShareLink={handleShareLink}
        />
      ),
      key: "2",
    },
  ];

  return (
    <div className={`relative order-2 ml-auto lg:ml-0 ${className} `}>
      {isCopied && (
        <div className="absolute top-[100%] z-10 mt-10 whitespace-nowrap rounded-md bg-gray-900 px-20 py-10 text-white-para md:right-0">
          Copied to the clipboard.
        </div>
      )}
      <Dropdown
        trigger={["click"]}
        destroyPopupOnHide={true}
        menu={{ items }}
        placement="bottomRight"
        className="share-button"
      >
        {shareType == SHARE_TYPE.PROFILE ? (
          <div
            className="flex cursor-pointer items-center gap-2 rounded-lg border border-gray-500 bg-white-button px-10 py-8 text-14 font-semibold leading-20 text-gray-500 md:px-16"
            onClick={() => {
              setShareData((prev: any) => ({
                ...prev,
                imageLink: image,
              }));
            }}
          >
            <Icon.IcShare />
            <span className="hidden md:block">Share</span>
          </div>
        ) : (
          <span className="cursor-pointer">
            <Icon.IcSharePost />
          </span>
        )}
      </Dropdown>
    </div>
  );
};

export default ShareButtonComponent;
