import React from "react";
import DataTableComponent from "./custom-datatable.component";
import DatatableHeader from "./datatable-header";
import DatatableFooter from "./datatable-footer";
import { IDataTable } from "src/types";

const CustomDatatableContainer = ({
  title,
  description,
  // className,
  button,
  data,
  columns,
  showHeader,
  showFooter,
  pagination,
  handleSearch,
  clearSearch,
  handlePageChange,
  handleItemsPerPageChange,
  onRowClicked
}: IDataTable) => {


  return (
    <DataTableComponent
      columns={columns}
      data={data}
      showFooter={showFooter}
      showHeader={onRowClicked}
      pagination={pagination}
      onRowClicked={onRowClicked}
      header={
        <DatatableHeader
          title={title}
          description={description}
          button={button}
          handleSearch={handleSearch}
          clearSearch={clearSearch}
          pagination={pagination}
        />
      }
      footer={
        <DatatableFooter
          pagination={pagination}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      }
    />
  );
};

export default CustomDatatableContainer;
