import { createContext, useContext } from "react";
import { IArticlesContext } from "./types";

import { useFormik } from "formik";
import { createArticleSchema } from "src/validations/create-article-schema";
import useLoadingError from "src/hooks/useLoadingError";

const ArticlesContext = createContext<IArticlesContext>({
  formik: null,
  loading: false,
  startLoading: null,
  stopLoading: null,
});

export const initialArticleValues = {
  id: "",
  type: "",
  title: "",
  subtitle: "",
  experts: [],
  related_posts: [],
  releated_profiles: [],
  description: "",
  image: "",
  image_description: "",
  sections: [
    {
      title: "Introduction",
      description: "",
      className: "add-section-min-height",
    },
  ],
};

export const ArticlesContextProvider = ({ children }: any) => {
  const { loading, startLoading, stopLoading } = useLoadingError();
  const formik = useFormik({
    initialValues: initialArticleValues,
    validationSchema: createArticleSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {},
  });

  return (
    <ArticlesContext.Provider
      value={{
        formik,
        loading,
        startLoading,
        stopLoading,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

export const useArticlesContext = () => useContext(ArticlesContext);
