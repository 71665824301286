"use client";
import React from "react";
import ConfirmDelete from "src/components/organisms/confirm-delete";

import { usePostsContext } from "src/context/posts-context";
import { IConfirmDeleteModal } from "../../confirm-delete/confirm-delete.component.types";
import CustomModal from "src/components/molecules/custom-modal";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import { POST_ACTIONS, PUBLISH_POST_BTN_TYPES } from "src/types";
import usePost from "src/hooks/usePost";
import useLoadingError from "src/hooks/useLoadingError";
const ConfirmPublishPostComponent = () => {
  const {
    isOpenPublishPost,
    togglePublishPost,
    publishPostType,
    publishPostId,
  } = usePostsContext();

  const { handlePostAction, handlePublishPost } = usePost();

  const { loading, startLoading, stopLoading } = useLoadingError();

  return (
    <CustomModal
      isOpen={isOpenPublishPost}
      openModal={togglePublishPost}
      toggle={togglePublishPost}
      innerClass="sm:w-[320px]"
    >
      <div className="relative flex w-full flex-col rounded-[30px] border border-border-color bg-gray-400">
        <button
          className="absolute right-[16px] top-[16px]"
          onClick={togglePublishPost}
        >
          <Icon.IcModalClose />
        </button>
        <div className="px-16 pb-16 pt-32">
          <div className="mx-auto mb-20 flex h-[86px] w-[86px] items-center justify-center rounded-[28px] bg-gray-300">
            <img
              className="mx-auto"
              src="/assets/images/confirm-pop-up-img.svg"
              width={48}
              height={48}
              alt="Brand Logo"
              title="Brand Logo"
              loading="eager"
            />
          </div>
          <h3 className="mb-8 text-center text-18 font-medium leading-28 text-white-heading">
            Are you sure you want to publish this user’s post?
          </h3>
        </div>
        <div className="px-16 pb-20 pt-16">
          <div className="flex flex-col gap-3">
            <CustomButton
              title={"Publish"}
              variant="primary"
              onClick={() => {
                startLoading();
                if (publishPostType === PUBLISH_POST_BTN_TYPES.DEFAULT) {
                  handlePostAction(POST_ACTIONS.PUBLISH).finally(() => {
                    stopLoading();
                    togglePublishPost();
                  });
                } else {
                  handlePublishPost(publishPostId).finally(() => {
                    stopLoading();
                    togglePublishPost();
                  });
                }
              }}
              loading={loading}
            />
            <CustomButton
              title={"Cancel"}
              variant="secondary"
              onClick={togglePublishPost}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmPublishPostComponent;
