import { Icon } from "src/components/atoms/icons";
import React from "react";
import { IShareTwitter } from "./types";

const ShareTwiiterComponent = ({ handleClick }: IShareTwitter) => {
  return (
    <li onClick={handleClick}>
      <div className="group flex items-center gap-2 py-12 pl-16 pr-16">
        <Icon.IcTwitter />
        <span className="whitespace-nowrap text-14 font-medium leading-20 text-white-para transition duration-[0.4s] group-hover:text-white">
          Share on X
        </span>
      </div>
    </li>
  );
};

export default ShareTwiiterComponent;
