import React from 'react'
import CustomDrawerComponent from './custom-drawer.component'
import { ICustomDrawer } from './types'

const CustomDrawerContainer = (props: ICustomDrawer) => {
  return (
    <CustomDrawerComponent {...props}/>
  )
}

export default CustomDrawerContainer