import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TextInput from "src/components/atoms/text-input";
import PanelLayout from "src/components/layouts/panel-layout";
import { toast } from "react-toastify";
import { ACTION_TYPE } from "src/types";
import SelectInput from "src/components/atoms/select-input";
import { useGetTopicMutation } from "src/services/topics";
import useModal from "src/hooks/useModal";
import AddTopicForm from "../topics/elements/add-topic-form";
import { splitKeywords } from "src/components/molecules/crop-modal-easy/utils";

const ViewTopic = () => {
  const { id } = useParams();
  const [getTopic, { data: res }] = useGetTopicMutation();

  // for edit topic
  const { isOpen: isOpenAddTopic, toggle: toggleAddTopic } = useModal();

  useEffect(() => {
    getTopic({ id });
  }, []);

  const handleTextareaResize = () => {
    const textarea = document.getElementById("text-area-feedback"); // Replace with your actual ID
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 20}px`;
    }
  };

  useEffect(() => {
    handleTextareaResize();
  }, [res]);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="grid gap-5 xl:grid-cols-12">
        <div className="order-2 block xl:order-1 xl:col-span-8 xxl:col-span-9">
          <div className="rounded-xl bg-gray-400">
            <div className="flex justify-between border-b border-border-color p-24">
              <h4 className="text-16 font-semibold leading-24 text-white-heading">
                Topic detail
              </h4>

              <button
                className="relative text-16 font-semibold leading-24 text-white-heading"
                onClick={toggleAddTopic}
              >
                <span className="absolute bottom-[-20px] right-0 block h-[250%]  w-[250%]"></span>
                Edit
              </button>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <TextInput
                  name="email"
                  type="email"
                  label="Title"
                  value={res?.data.title}
                  disabled={true}
                />
                <div className="">
                  <label className="mb-6 block text-14 font-medium leading-20 text-white-heading">
                    Description
                  </label>
                  <div className="relative">
                    <textarea
                      className="max-h-[500px] w-full cursor-not-allowed resize-none overflow-y-auto rounded border border-border-color bg-input-background px-16 py-11 text-16 font-normal leading-24 text-placeholder-text-color"
                      value={res?.data?.description}
                      id="text-area-feedback"
                      onChange={handleTextareaResize}
                      disabled={true}
                    >
                      {res?.data?.description}
                    </textarea>
                  </div>
                </div>
                <SelectInput
                  label="Keywords"
                  name="keywords"
                  options={[]}
                  containerClassName="tags-input tags-input-scroll-hidden"
                  mode="tags"
                  required={true}
                  placeholder=""
                  // value={res?.data?.keywords?.split(",")}
                  value={splitKeywords(res?.data?.keywords)}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="order-1 xl:order-2 xl:col-span-4 xxl:col-span-3">
          <div className="rounded-xl bg-gray-400">
            <div className="flex flex-wrap items-center justify-between gap-2 border-b border-border-color p-24">
              <h4 className="text-16 font-semibold leading-24 text-white-heading">
                Analytics
              </h4>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <TextInput
                  name="Related posts"
                  type="email"
                  label="Related posts"
                  value={res?.data?.related_posts_count?.toString()}
                  disabled={true}
                />
                <TextInput
                  name="views"
                  type="email"
                  label="Views"
                  value={res?.data?.views_count?.toString()}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <AddTopicForm
        isOpen={isOpenAddTopic}
        toggle={toggleAddTopic}
        fetchData={() => getTopic({ id })}
        actionType={ACTION_TYPE.EDIT}
        initialValues={{
          id: res?.data?.id,
          title: res?.data?.title,
          description: res?.data?.description,
          keywords: splitKeywords(res?.data?.keywords),
        }}
      />
    </PanelLayout>
  );
};

export default ViewTopic;
