import React from 'react'
import DataTableSearch from '../datatable-search'
import { IDataTable } from 'src/types'

const DatetableHeaderComponent = ({ title, description,button, handleSearch,pagination, clearSearch}: Partial<IDataTable>) => {
    return (
        <div className="flex items-center justify-between px-12 py-16 bg-gray-400 rounded-t-xl border-b border-border-color gap-4 lg:gap-8 flex-wrap">
            <div className='flex flex-col gap-1'>
                <h2 className="text-white-heading text-24 font-bold leading-32">{title}</h2>
                <div className="text-white-para text-14 font-normal leading-24">{pagination?.loading ?"Loading..." :description}</div>
            </div>
            <div className='flex items-center gap-3 w-full justify-between lg:justify-normal lg:w-auto flex-wrap'>
                <div className="flex w-full sm:w-auto order-2 sm:order-1">
                    {true &&
                        <DataTableSearch query={pagination?.query} handleSearch={handleSearch} clearSearch={clearSearch}/>
                    }
                </div>
                <div className="flex w-full sm:w-auto order-1 sm:order-1">
                    {button}
                </div>
            </div>
        </div>
    )
}

export default DatetableHeaderComponent