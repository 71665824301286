import React, { useEffect } from 'react'
import { IEditEmail } from './types'
import { useFormik } from 'formik';
import { showToast } from 'src/utils';
import { toast } from 'react-toastify';
import { TOASTR_TYPES } from 'src/types';
import EditEmailComponent from './edit-email.component';
import { useAppSelector } from 'src/store/hook';
import { otherUserSelector } from 'src/store/features/user/selectors';
import { changeEmailSchema } from 'src/validations/change-email-schema';
import { useEditUserEmailMutation, useGetUserMutation } from 'src/services/users';
import { useParams } from 'react-router-dom';
import useLoadingError from 'src/hooks/useLoadingError';

const EditEmailContainer = (props: IEditEmail) => {

  
  const {loading, startLoading, stopLoading} = useLoadingError()
  const [editUserEmail] = useEditUserEmailMutation();
  const [getUser, { data }] = useGetUserMutation<any>();
  
  const otherUser = useAppSelector(otherUserSelector);
  const {id} = useParams();


  const formik = useFormik({
    initialValues: {
      email: otherUser?.email
    },
    validationSchema: changeEmailSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit:  (values,{setSubmitting}) => {

      startLoading()
      editUserEmail({
        email: values?.email,
        id
      }).unwrap().then((res) => {
        getUser({id})
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        props?.toggle();
        formik.resetForm();
        
      }).catch((err:any) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message)        
      }).finally(() => {
        stopLoading()
          setSubmitting(false)
      })


    }
})



useEffect(() => {
      formik?.resetForm()
},[props?.isOpen]);








  return (
     <EditEmailComponent {...props} formik={formik} loading={loading}/>
  )
}

export default EditEmailContainer