import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import MainSection from "./elements/main-section";
import Footer from "./elements/footer";

const CreateUser = () => {
  return (
    <>
      <PanelLayout wrapperClass={``}>
        <MainSection />
        <Footer />
      </PanelLayout>
    </>
  );
};

export default CreateUser;
