import React, { useEffect, useRef, useState } from "react";
import Contents from "../../profile/contents";
import { Icon } from "src/components/atoms/icons";
import PersonalDetail from "../../profile/personal-detail";
import { useNavigate, useParams } from "react-router-dom";
import "./biography-view.css";

const BiographyViewComponent = ({
  user,
  searchParams,
  contentsClassName,
}: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const lastSectionRef = useRef(null);

  const handleClick = (i: any) => {
    navigate(`/admin/users/${id}/edit-bio?section=section-${i}`);
  };

  const sections = user?.biography?.map((val: any) => val.id);
  const [activeSection, setActiveSection] = useState(0);
  const handleScroll = () => {
    const headerOffset = 100;
    const navbarHeight = 50;
    for (let i = sections.length - 1; i >= 0; i--) {
      const targetSection = document.getElementById(sections[i]);
      if (targetSection) {
        const sectionRect = targetSection.getBoundingClientRect();
        if (sectionRect.top - headerOffset <= navbarHeight) {
          setActiveSection(i);
          break;
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const handleSidebarItemClick = (index: number) => {
    const targetSection = document.getElementById(sections[index]);
    if (targetSection) {
      var headerOffset = 100;
      var elementPosition: any = targetSection?.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 1200;
    if (!isMobile && lastSectionRef.current) {
      const lastSectionHeight: number = (
        lastSectionRef.current as HTMLDivElement
      ).offsetHeight;
      const marginBottom: number = Math.max(
        window.innerHeight - lastSectionHeight - 80,
        0,
      );

      (lastSectionRef.current as HTMLDivElement).style.marginBottom =
        `${marginBottom}px`;
    }
  }, [activeSection]);

  return (
    <div className="flex flex-col gap-8 rounded-xl bg-gray-400 p-24 xl:flex-row ">
      <>
        <Contents
          sections={user?.biography}
          activeSection={activeSection}
          handleSidebarItemClick={handleSidebarItemClick}
          contentsClassName={contentsClassName}
        />

        <div className="order-3 xl:order-2 xl:w-[60%]">
          <div className="" id={`content-container`}>
            {user?.biography?.map((section: any, i: number) => {
              return (
                <div
                  className="mb-30"
                  id={sections[i]}
                  key={i}
                  ref={i === sections.length - 1 ? lastSectionRef : null}
                >
                  <div className="relative flex items-center gap-1 xl:px-24">
                    <h2 className="text-18 font-semibold leading-28 text-white-heading">
                      {section?.title}
                    </h2>
                    {searchParams?.type !== "preview" && (
                      <button
                        className="cursor-pointer"
                        onClick={() => handleClick(i)}
                      >
                        <Icon.IcPencil />
                      </button>
                    )}
                  </div>
                  <div
                    className="description-paragraph ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline text-14 font-normal leading-24 text-white-para "
                    dangerouslySetInnerHTML={{ __html: section?.description }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </>
      <PersonalDetail profile={user} />
    </div>
  );
};

export default BiographyViewComponent;
