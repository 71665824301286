import React from 'react'
import CardComponent from './card.component'
import { ICard } from './types'

const CardContainer = (props: ICard) => {
    return (
        <CardComponent {...props}/>
    )
}

export default CardContainer