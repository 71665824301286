
import React, { useEffect, useState } from 'react'
import { IPersonalDetailModalModal } from './personal-detail-modal.types'
import PersonalDetailModalComponent from './personal-detail-modal.component'
import { useFormik } from 'formik'
import { personalInfoSchema } from 'src/validations/personal-info-schema'
import { toast } from 'react-toastify'
import { showToast } from 'src/utils'
import { TOASTR_TYPES } from 'src/types'
import { useAppSelector } from 'src/store/hook'
import { otherUserSelector } from 'src/store/features/user/selectors'
import { useEditUserMutation, useGetUserMutation } from 'src/services/users'
import { useParams } from 'react-router-dom'
import { CONSTANTS } from 'src/constants'

const PersonalDetailModalContainer = (props: IPersonalDetailModalModal) => {


  const [isDisabled] = useState(false)

  const otherUser = useAppSelector(otherUserSelector);
  const [editUser] = useEditUserMutation();
  const [getOtherUser] = useGetUserMutation()
  const { id } = useParams();

  console.log('===otherUser', otherUser)

  useEffect(() => {


    let isPublish =  "";

    if(otherUser?.admin_publish_hidden){
         if(otherUser?.admin_publish_hidden === CONSTANTS.USER_PROFILE_STATUS.PUBLISHED){
            isPublish = '1'
         }else{
            isPublish = '0'
         }
    }else{
           isPublish = otherUser?.is_publish?.toString()
    }

    formik.setValues({
      firstName: otherUser?.first_name,
      lastName: otherUser?.last_name,
      country: otherUser?.country,
      city: otherUser?.city,
      dob: otherUser?.dob,
      profession: otherUser?.profession,
      officeName: otherUser?.office_name,
      education: otherUser?.education,
      image: otherUser?.image,
      height:  otherUser?.height,
      website:otherUser?.website,
      twitterLink:otherUser?.twitter_link,
      linkedinLink: otherUser?.linkedin_link
    });

    formik?.setTouched({})

  }, [otherUser, props?.isOpen])



  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      city: "",
      dob: "",
      profession: "",
      officeName: "",
      education: "",
      image: "",
      height: "",
      website: "",
      twitterLink: "",
      linkedinLink: ""
      // isPublish: "1"
    },
    validationSchema: personalInfoSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      try {

        editUser({
          ...values,
          userId: id,
        }).unwrap().then((res) => {
          showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
          props?.toggle();
          formik.resetForm();
          getOtherUser({ id })

        }).catch((err: any) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message)
        }).finally(() => {
          setSubmitting(false)
        })

      } catch (err: any) {
        toast(err?.response?.data?.message)
      }


    },
  });


  return (
    <PersonalDetailModalComponent {...props} formik={formik} isDisabled={isDisabled} setIsDisabled={isDisabled} />
  )
}

export default PersonalDetailModalContainer