"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { useArticlesContext } from "src/context/articles-context";

import useArticle from "src/hooks/useArticle";
import { ARTICLE_ACTIONS } from "src/types";

const SaveArticleBtnComponent = ({}: any) => {
  const { loading, handleArticleAction } = useArticle();
  const { loading: loadingArticle } = useArticlesContext();

  return (
    <>
      <CustomButton
        title="Save"
        variant="custom"
        className="relative rounded-xl bg-white-button px-24 py-9 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none"
        onClick={() => handleArticleAction(ARTICLE_ACTIONS.SAVE)}
        loading={loading}
        disabled={loadingArticle}
      />
    </>
  );
};

export default SaveArticleBtnComponent;
