"use client";

import React from "react";
import { useNavigate } from "react-router-dom";
import BiographyEditable from "src/components/organisms/biography/biography-editable";
import SectionsEditable from "src/components/organisms/sections/sections-editable";
import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import { useRegisterContext } from "src/context/register-context";

const MyBiographyComponent = () => {
  const { formik } = useRegisterContext();

  console.log(formik, "formik set up account");

  return (
    // <BiographyEditable className="bg-gray-400 border border-border-color rounded-b-xl border-t-0 p-20 default-section-template biography-section-height" showHeader={true} formik={formik} />
    <>
      <SectionsEditable
        formik={formik}
        profile={null}
        showHeader={true}
        handleBlur={() => {}}
        contentType={CONTENT_TYPE.BIO}
        saveOnDragEnd={false}
        saveOnFocusOut={false}
        className="default-section-template biography-section-height rounded-b-xl border border-t-0 border-border-color bg-gray-400 p-20"
      />
    </>
  );
};

export default MyBiographyComponent;
