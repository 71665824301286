
import React from 'react'
import { Icon } from "src/components/atoms/icons"
import CustomButton from 'src/components/atoms/button'
import AuthLayout from "src/components/layouts/auth-layout"
import { useNavigate } from "react-router-dom"



const ResetPasswordSuccess = () => {

  const redirect = useNavigate()
  return (
    <>
      <AuthLayout>
        <div className="text-center">
          <div className="mb-32 flex items-center justify-center">
            <div className="w-[120px] h-[120px] bg-gray-800 rounded-[28px] flex items-center justify-center">
              <Icon.IcPrs />
            </div>
          </div>
          <div className="mb-32">
            <h4 className="mb-8 text-white-heading text-36 font-bold leading-44 tracking-0.72">Success!</h4>
            <p className="mb-8 text-white-para text-14 font-normal leading-20">Your password has been successfully changed</p>
          </div>
          <CustomButton title='Back to login page' onClick={() => {
            redirect('/login');

          }} />
        </div>
      </AuthLayout>
    </>


  )
}

export default ResetPasswordSuccess