"use client";
import React from "react";
import DeletePostComponent from "./delete-post.component";
import ConfirmDelete from "src/components/organisms/confirm-delete";

import { usePostsContext } from "src/context/posts-context";

const DeletePostContainer = () => {
  return <DeletePostComponent />;
};

export default DeletePostContainer;
