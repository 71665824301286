import React, { useEffect, useState } from "react";
import ImageUpload from "src/components/atoms/image-upload";
import SelectInput from "src/components/atoms/select-input";
import TextInput from "src/components/atoms/text-input";
import TextArea from "src/components/atoms/textarea";
import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import SectionsEditable from "src/components/organisms/sections/sections-editable";
import { ARTICLE_STATUS, ARTICLE_TYPES, TODO } from "src/types";

import useQueryParams from "src/hooks/useQueryParams";
import {
  initialArticleValues,
  useArticlesContext,
} from "src/context/articles-context";

import ExpertsInput from "src/components/molecules/experts-input";
import PostsInput from "src/components/molecules/posts-input";

const CreateArticleFormComponent = ({ isEdit, initialData }: TODO) => {
  const { getQueryParam } = useQueryParams();
  const section = getQueryParam("section");
  const [disabled, setDisabled] = useState();

  const { formik } = useArticlesContext();

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  useEffect(() => {
    formik.setValues(
      isEdit
        ? {
            id: initialData?.id,
            type: initialData?.type,
            title: initialData?.title,
            subtitle: initialData?.subtitle,
            experts: initialData?.experts?.map((e: any) => e?.id),
            related_posts: initialData?.related_posts?.map((e: any) => e?.id),
            description: initialData?.description,
            image: initialData?.image,
            image_description: initialData?.image_description,
            sections: JSON.parse(
              JSON.stringify(
                initialData?.content?.length == 0
                  ? [
                      {
                        title: "Introduction",
                        description: "",
                        className: "add-section-min-height",
                      },
                    ]
                  : initialData?.content || [
                      {
                        title: "Introduction",
                        description: "",
                        className: "add-section-min-height",
                      },
                    ],
              ),
            ),
          }
        : JSON.parse(JSON.stringify(initialArticleValues)),
    );
  }, [initialData]);

  useEffect(() => {
    let interval = setInterval(() => {
      const element: any = document.getElementById(section);

      if (element) {
        const headerHeight: any = document.getElementById("top")
          ? document.getElementById("top")?.offsetHeight
          : "";

        // Calculate the top position considering the sticky header
        const topPosition =
          element?.getBoundingClientRect().top +
          window.pageYOffset -
          (headerHeight + 170);

        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });

        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {
    console.log(formik?.values, "formik values");
  }, [formik?.values]);

  return (
    <>
      <div className="default-section-template h-full p-16">
        <div className="flex flex-col gap-y-8">
          <SelectInput
            name="type"
            label="Type"
            value={formik?.values?.type ? formik?.values?.type : []}
            showError={Boolean(formik?.touched.type)}
            errorMessage={formik?.errors.type}
            options={[
              {
                label: "People",
                value: ARTICLE_TYPES.PEOPLE,
              },
              {
                label: "Product / Company",
                value: ARTICLE_TYPES.TOPIC,
              },
            ]}
            onSelect={(value: string) => formik?.setFieldValue("type", value)}
            containerClassName="theme-form-select "
            required={true}
            placeholder="Select a type"
            searchable={true}
            disabled={initialData?.status === ARTICLE_STATUS.PUBLISH}
          />

          {formik?.values?.type && (
            <>
              <TextInput
                name="title"
                type="text"
                label="Title"
                containerClassName=""
                value={formik?.values.title}
                showError={Boolean(formik?.touched.title)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.title}
                required={true}
                placeholder="Enter your title here"
              />
              <TextInput
                name="subtitle"
                type="text"
                label="Subtitle"
                containerClassName=""
                value={formik?.values.subtitle}
                showError={Boolean(formik?.touched.subtitle)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.subtitle}
                required={false}
                placeholder="Enter your subtitle here"
              />

              <ExpertsInput
                formik={formik}
                label={
                  formik?.values?.type === ARTICLE_TYPES.TOPIC
                    ? "Experts in the field"
                    : "Related PIO-E Profiles"
                }
                fieldName="experts"
                placeholder={
                  formik?.values?.type === ARTICLE_TYPES.TOPIC
                    ? "Choose experts here"
                    : "Choose profiles here"
                }
                initialData={isEdit ? initialData?.experts : []}
              />

              {formik?.values?.type == ARTICLE_TYPES.TOPIC && (
                <PostsInput
                  formik={formik}
                  initialData={isEdit ? initialData?.related_posts : []}
                />
              )}
              <TextArea
                name="description"
                label="Description"
                value={formik?.values.description}
                showError={Boolean(formik?.touched.description)}
                onChange={(e: TODO) => {
                  formik?.handleChange(e);
                }}
                onBlur={(e: TODO) => {
                  formik?.handleBlur(e);
                }}
                errorMessage={formik?.errors.description}
                autoFocus={false}
                placeholder="Enter your description here.."
              />
              <div className="flex w-full flex-col gap-8 md:flex-row">
                <ImageUpload
                  label="Upload article image"
                  name="image"
                  value={formik?.values?.image}
                  image={formik?.values?.image}
                  className="!h-[240px]  !w-[240px] bg-gray-400 "
                  onChange={(img: string) =>
                    formik?.setFieldValue("image", img)
                  }
                  onDelete={() => {
                    formik?.setFieldValue("image", "");
                  }}
                  showError={Boolean(formik?.touched.image)}
                  errorMessage={formik?.errors.image}
                  setDisabled={setDisabled}
                  formik={formik}
                  isOpenModal={true}
                  required={false}
                  useCropper={true}
                  disableAspectRatio={false}
                />
                <TextInput
                  name="image_description"
                  type="text"
                  label="Description"
                  containerClassName="w-full"
                  value={formik?.values.image_description}
                  showError={Boolean(formik?.touched.image_description)}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  errorMessage={formik?.errors.image_description}
                  required={false}
                  placeholder="Enter image description here.."
                />
              </div>
              <SectionsEditable
                formik={formik}
                saveOnFocusOut={true}
                saveOnDragEnd={true}
                handleBlur={() => {}}
                contentType={CONTENT_TYPE.ARTICLE}
                profile={null}
                showHeader={false}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateArticleFormComponent;
