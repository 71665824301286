import React from "react";
import { ICustomButton } from "./button.types";
import ButtonLoader from "../button-loader/button-loader.component";

const CustomButton = ({
  title,
  type = "button",
  className = "",
  loading = false,
  variant = "primary",
  onClick,
  disabled = false,
  children,
}: ICustomButton) => {
  if (variant === "primary") {
    return (
      <>
        <button
          className={`relative rounded-xl bg-white-button px-32 py-12 text-16 font-medium leading-24 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button  focus:outline-none disabled:cursor-not-allowed ${className}`}
          type={type}
          onClick={onClick}
          disabled={loading || disabled}
        >
          <ButtonLoader loading={loading} />{" "}
          <span className={`${loading ? "opacity-0" : ""}`}>{title}</span>
        </button>
      </>
    );
  } else if (variant === "custom") {
    return (
      <>
        <button
          className={` ${className}`}
          type={type}
          onClick={onClick}
          disabled={disabled}
        >
          <ButtonLoader loading={loading} />{" "}
          <span className={`${loading ? "opacity-0" : ""}`}>
            {title || children}
          </span>
        </button>
      </>
    );
  } else if (variant === "gradient") {
    return (
      <>
        <button
          className={`bg-button-gradient relative rounded-xl px-12 py-10 text-14 font-normal leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button  focus:outline-none disabled:cursor-not-allowed ${className}`}
          type={type}
          onClick={onClick}
          disabled={loading}
        >
          <ButtonLoader loading={loading} />{" "}
          <span className={`${loading ? "opacity-0" : ""}`}>{title}</span>
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
          className={`relative rounded-xl border border-white-button px-32 py-11 text-16 font-medium leading-24 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none disabled:cursor-not-allowed ${className}`}
          type={type}
          onClick={onClick}
          disabled={loading || disabled}
        >
          <ButtonLoader loading={loading} />{" "}
          <span className={`${loading ? "opacity-0" : ""}`}>{title}</span>
        </button>
      </>
    );
  }
};

export default CustomButton;
