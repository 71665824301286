import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import "./custom-datatable.css";
import { IDataTable } from "src/types";
import AppLoader from "src/components/atoms/app-loader/app-loader.component";

const DataTableComponent = ({
  columns,
  data,
  header,
  footer,
  pagination,
  onRowClicked,
}: Partial<IDataTable>) => {
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#2A2F3B",
        color: "#E0DBDB",
        padding: "14px 16px",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        borderBottom: "1px solid #63676C",
      },
    },
    cells: {
      style: {
        // backgroundColor: "#191C24",
        color: "#BAB8B8",
        padding: "16px 16px",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "24px",
        borderBottom: "1px solid #63676C",
        cursor: onRowClicked ? "pointer" : "default",
      },
    },
    rows: {
      style: {
        backgroundColor: "#191C24",
        "&:hover": {
          backgroundColor: "#2A2F3B", // Light grey background on hover
          cursor: "pointer", // Change cursor to pointer on hover
        },
      },
    },
  };

  return (
    <>
      {header}
      <>
        {pagination?.loading ? (
          <div className="sc-hzhJZQ gmlwXk w-full">
            <div className="sc-aXZVg fgkzGI rdt_Table w-full" role="table">
              <div className="sc-hmdomO cTsUPQ w-full">
                <div className="p-24">
                  {pagination?.loading ? <AppLoader /> : "No Results Found"}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DataTable
            onRowClicked={onRowClicked}
            columns={columns}
            data={data}
            customStyles={customStyles}
          />
        )}
        {footer}
      </>
    </>
  );
};

export default DataTableComponent;
