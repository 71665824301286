import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import CreateArticleForm from "src/components/organisms/articles/create-article-form";
import PreviewArticleBtn from "src/components/organisms/articles/preview-article-btn";
import PublishArticleBtn from "src/components/organisms/articles/publish-article-btn";
import SaveArticleBtn from "src/components/organisms/articles/save-article-btn";
import { useArticlesContext } from "src/context/articles-context";
import { useGetArticleMutation } from "src/services/articles";

const EditArticle = () => {
  const [getArticle, { data }] = useGetArticleMutation();

  const { id } = useParams();
  useEffect(() => {
    getArticle({ id });
  }, []);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="sticky top-0 z-20 flex flex-col items-start justify-between gap-4  rounded-tl-lg rounded-tr-lg bg-gray-400 p-16  md:flex-row md:items-center md:gap-0 ">
        <h3 className="text-24 font-bold leading-8 text-white-heading">
          Edit an Article
        </h3>
        <div className="relative flex gap-2">
          <PreviewArticleBtn />
          <SaveArticleBtn />
          <PublishArticleBtn />
        </div>
      </div>
      <CreateArticleForm isEdit={true} initialData={data?.data} />
    </PanelLayout>
  );
};

export default EditArticle;
