export const editorConfig: any = {
  mediaEmbed: {
    previewsInData: true,
    providers: [
      {
        name: "dailymotion",
        url: /^dailymotion\.com\/video\/(\w+)/,
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
            `<iframe src="https://www.dailymotion.com/embed/video/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
            "</iframe>" +
            "</div>"
          );
        },
      },

      {
        name: "spotify",
        url: [/^https?:\/\/open\.spotify\.com\/(\w+\/\w+)/],
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 50%;">' +
            `<iframe src="https://open.spotify.com/embed/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" allowtransparency="true" allow="encrypted-media">' +
            "</iframe>" +
            "</div>"
          );
        },
      },
      {
        name: "apple",
        url: [/^https?:\/\/podcasts\.apple\.com\/(\w+\/\w+)/],
        html: (match: any) => {
          const url: any = match.input;
          const replacedUrl = url.replace("https://", "https://embed.");

          return `<iframe
                        allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                        frameborder="0"
                        height="175"
                        style="width:100%;overflow:hidden;border-radius:10px;"
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                        src=${replacedUrl}
                      ></iframe>`;
        },
      },

      {
        name: "youtube",
        url: [
          /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
          /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
          /^youtube\.com\/embed\/([\w-]+)/,
          /^youtu\.be\/([\w-]+)/,
          /^youtube\.com\/shorts\/([\w-]+)/, // New pattern for YouTube shorts
        ],
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
            `<iframe src="https://www.youtube.com/embed/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
            "</iframe>" +
            "</div>"
          );
        },
      },

      {
        name: "vimeo",
        url: [
          /^vimeo\.com\/(\d+)/,
          /^vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
          /^vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
          /^vimeo\.com\/channels\/[^/]+\/(\d+)/,
          /^vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
          /^vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
          /^player\.vimeo\.com\/video\/(\d+)/,
        ],
        html: (match: any) => {
          const id = match[1];

          return (
            '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
            `<iframe src="https://player.vimeo.com/video/${id}" ` +
            'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
            'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
            "</iframe>" +
            "</div>"
          );
        },
      },

      {
        name: "instagram",
        url: /^instagram\.com\/p\/(\w+)/,
      },
      {
        name: "twitter",
        url: /^twitter\.com/,
      },
      {
        name: "googleMaps",
        url: /^google\.com\/maps/,
      },
      {
        name: "flickr",
        url: /^flickr\.com/,
      },
      {
        name: "facebook",
        url: /^facebook\.com/,
      },
    ],
  },
  toolbar: {
    items: [
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
      "imageUpload",
      "mediaEmbed",
    ],
  },
  placeholder: "Add a body...",
};
