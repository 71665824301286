import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";
import RatingStars from "../../../../components/atoms/rating-stars";
import { capitalizeFirstLetter } from "src/utils";

export const columns = (showDeletePopup: any) => {
  return [
    {
      name: "Name",
      selector: (row: { user_name: string }) => row?.user_name,
      sortable: true,
      width: "162px",
    },
    {
      name: "Role",
      selector: (row: { user_role: string }) =>
        capitalizeFirstLetter(row?.user_role),
      sortable: false,
      width: "84px",
    },
    {
      name: "Title",
      selector: (row: { title: string }) => row?.title,
      sortable: false,
      width: "319px",
    },
    {
      name: "Feedback",
      selector: (row: { feedback: string }) => row?.feedback,
      sortable: false,
      width: "313px",
    },
    {
      name: "Rating",
      cell: (row: any) => {
        return (
          <div className="relative">
            <Link
              to={`/admin/user-feedback/${row?.id}`}
              className="absolute z-[1] h-full w-full"
            ></Link>
            <RatingStars value={row?.rate} />
          </div>
        );
      },
      allowOverflow: true,
      sortable: true,
      width: "144px",
      sortFunction: (a: any, b: any) => {
        return Number(a?.rate) - Number(b?.rate); // Ascending order
      },
    },

    {
      name: "",
      cell: (row: any) => {
        return (
          <>
            <div className="flex items-center justify-end">
              <Link to={`/admin/user-feedback/${row?.id}`}>
                <button className="p-12 cursor-pointer">
                  <Icon.IcView />
                </button>
              </Link>
              <button
                className="p-12 cursor-pointer"
                onClick={() => showDeletePopup(row?.id)}
              >
                <Icon.IcDelete />
              </button>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
};
