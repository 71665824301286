import React from "react";

import PersonalInfo from "../personal-info";
BiographyEditable;
import MyBiography from "../my-biography";
import BiographyEditable from "src/components/organisms/biography/biography-editable";

const MainComponent = () => {
  return (
    <main className="p-24">
      <div className="main-container">
        <div className="grid gap-5 xl:grid-cols-12 xl:gap-4">
          <div className="order-2 block xl:order-1 xl:col-span-8 xxl:col-span-9">
            <MyBiography />
          </div>
          <div className="order-1 xl:order-2 xl:col-span-4 xl:pb-70 xxl:col-span-3">
            <PersonalInfo />
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainComponent;
