import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import ImageUploadComponent from "./image-upload.component";
import { IImageUpload } from "./image-upload.types";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import CustomModal from "src/components/molecules/custom-modal";
import useModal from "src/hooks/useModal";
import CropModalEasy from "src/components/molecules/crop-modal-easy";
import { clearFileInput } from "./canvas-utils";
import imageCompression from "browser-image-compression";
import { useUploadFileMutation } from "src/services/auth";

const ImageUploadContainer = (props: IImageUpload) => {
  const [uploadFile] = useUploadFileMutation();
  const { toggle, isOpen, closeModal, openModal } = useModal();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const setCroppedImageUrlCallback = useCallback(
    (url: any) => {
      setCroppedImageUrl(url);
    },
    [croppedImageUrl],
  );

  const [imgSrc, setImgSrc] = useState("");
  const [imageName, setImageName] = useState(props.image);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const { loading, error } = useLoadingError();

  function onSelectFileCropper(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile: any = e?.target.files && e.target.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024); //     if (selectedFile) {
    const allowedFormats = ["image/jpeg", "image/png", "application/jpg"];
    if (!allowedFormats.includes(selectedFile.type)) {
      showToast(toast, TOASTR_TYPES.ERROR, "This file format is not allowed.");
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      return;
    }
    if (fileSizeInMB > 10) {
      showToast(
        toast,
        TOASTR_TYPES.ERROR,
        "The image field must not be greater than 10 MB",
      );
      return;
    }

    if (e.target.files && e.target.files.length > 0) {
      setCrop({ x: 0, y: 0 });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || ""),
      );
      reader.readAsDataURL(e.target.files[0]);
      openModal();
    }
  }
  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile: any = e?.target.files && e.target.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024); //     if (selectedFile) {
    const allowedFormats = ["image/jpeg", "image/png", "application/jpg"];
    if (!allowedFormats.includes(selectedFile.type)) {
      showToast(toast, TOASTR_TYPES.ERROR, "This file format is not allowed.");
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      return;
    }
    if (fileSizeInMB > 10) {
      showToast(
        toast,
        TOASTR_TYPES.ERROR,
        "The image field must not be greater than 10 MB",
      );
      return;
    }

    if (e.target.files && e.target.files.length > 0) {
      const blob = e.target.files[0];

      // Get the size of the blob in bytes
      const sizeInBytes = blob.size;

      // Convert bytes to megabytes
      const sizeInMegabytes = sizeInBytes / (1024 * 1024);

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const uniqueFileName = `croppedImage_${Date.now()}.jpg`;
      const file = new File([blob], uniqueFileName, { type: blob.type });

      const compressedFile = await imageCompression(file, options);

      const response: any = await uploadFile({
        image: compressedFile,
      }).unwrap();

      const imageName = response?.data?.image_name;
      setImageName(imageName);
      setImgSrc(imageName);
      props.onChange && props.onChange(imageName);
    }
  }

  const handleDelete = () => {
    if (setImgSrc) {
      setImgSrc("");
      setImageName("");
    }
    props.onDelete && props.onDelete();
    let input: any = document.getElementById("input-file");
    if (input) {
      input.value = "";
    }
  };
  useEffect(() => {
    if (props?.image) {
      setImgSrc(props.image);
      setImageName(props.image);
    } else {
      setImgSrc("");
      setImageName("");
    }
  }, [props.image]);

  useLayoutEffect(() => {
    if (!isOpen && !imageName) {
      handleDelete();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!props?.isOpenModal) {
      handleDelete();
    } else {
      setImgSrc(props.image);
      setImageName(props.image);
    }
  }, [props?.isOpenModal]);

  useEffect(() => {
    if (!isOpen) {
      clearFileInput();
    }
  }, [isOpen]);

  return (
    <>
      {props?.isOpenModal && (
        <>
          <CustomModal
            isOpen={isOpen}
            toggle={toggle}
            innerClass="md:w-[550px]"
          >
            <CropModalEasy
              toggle={toggle}
              isOpen={isOpen}
              src={imgSrc}
              setCroppedImageUrl={setCroppedImageUrlCallback}
              setImage={setImgSrc}
              crop={crop}
              setImageName={setImageName}
              setCrop={setCrop}
              onChange={props.onChange}
              defaultAspectRatio={props.defaultAspectRatio}
              disableAspectRatio={props.disableAspectRatio}
            />
          </CustomModal>
          <ImageUploadComponent
            {...props}
            image={imageName}
            handleDelete={handleDelete}
            handleChange={
              props?.useCropper ? onSelectFileCropper : onSelectFile
            }
            loading={loading}
            uploadError={error || ""}
          />
        </>
      )}
    </>
  );
};

export default ImageUploadContainer;
