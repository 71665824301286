import React, { useEffect, useRef } from "react";
import { Icon } from "src/components/atoms/icons";
import useDropdownToggle from "src/hooks/useCustomDropdown";
import { Link } from "react-router-dom";
import LogoutButton from "src/components/atoms/logout-button";

const ProfileDropDown = () => {
  const { isOpen, toggleDropdown, closeDropdown } = useDropdownToggle(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="order-1 ml-auto md:order-2">
      <button
        className="relative flex items-center gap-3"
        onClick={toggleDropdown}
      >
        <div className="profile-pic relative flex items-center gap-3">
          <span className="text-14 font-normal leading-20 text-white-heading">
            Admin
          </span>
          <div className="h-[28px] w-[28px] shrink-0 rounded-full">
            <img
              className="w-full] h-full object-cover"
              src={"/assets/images/admin-profile.svg"}
              alt="profile-image"
            />
          </div>
        </div>

        <div
          className={`dropdown-wrapper absolute right-0 top-[100%] z-[100] mt-10 min-w-[139px] sm:mt-30 ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <ul className="rounded-xl bg-gray-400">
            <li>
              <Link
                to="/admin/settings"
                className="white-link-with-icon flex items-center gap-2 py-12 pl-16 pr-16"
              >
                <Icon.IcSettingsAdmin />
                <span className="whitespace-nowrap text-16 font-normal leading-24 text-white-heading">
                  Settings
                </span>
              </Link>
            </li>
            <li>
              <LogoutButton />
            </li>
          </ul>
        </div>
      </button>
    </div>
  );
};

export default ProfileDropDown;
