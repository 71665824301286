import React, { useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import CardLayout from "src/components/organisms/card-layout";
import { useGetAllTopicsMutation } from "src/services/topics";
import DataTable from "../elements/data-table";

const Library = () => {
  return (
    <>
      <PanelLayout wrapperClass={`px-24 py-20`}>
        <DataTable />
      </PanelLayout>
    </>
  );
};

export default Library;
