import React from 'react'
import { Icon } from 'src/components/atoms/icons';

const DataTableSearchComponent = ({query, handleSearch, clearSearch}:any) => {
  return (
    <div className="relative order-3 xl:order-2 w-full xl:w-[214px]">
      <input
        className="w-full pl-16 pr-44 py-11 rounded border border-border-color bg-input-background text-16 font-normal leading-24 text-input-text-color placeholder:text-placeholder-text-color focus:outline-none focus:shadow-input-shadow text-ellipsis"
        placeholder='Search'
        value={query}
        onChange={(e) => {
          handleSearch(e.target.value);
        }
        }
      />
     
      {query ? 
      <div className="absolute top-[13px] right-[14px] flex items-center">
          <button onClick={() => {
            clearSearch && clearSearch()
          }}>
            <Icon.IcCross />
          </button>
      </div>:  <div className="absolute top-[13px] right-[16px]">
        <Icon.IcSearch />
      </div>
            }
    </div>
  )
}

export default DataTableSearchComponent