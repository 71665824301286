import React, { useEffect, useState } from "react";
import TextInput from "../text-input";
import { ISingleSectionData } from "src/components/organisms/biography/biography-editable/single-section/single-section.types";
import { Icon } from "../icons";
import { IBiography } from "src/types";
import { getBiographyError } from "src/context/register-context/types";
import { initialBioTitles } from "src/constants";

const HeadingEditableComponent = ({
  id,
  bio,
  disabled,
  formik,
}: ISingleSectionData) => {
  // const { title } = formik?.values?.biography[id];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { name, value } = event.target;
    if (formik?.values?.sections) {
      const sections: IBiography[] = [...formik?.values?.sections];
      sections[index]["title"] = value;

      console.log(sections, "sections>>>");

      formik?.setValues({
        ...formik.values,
        sections: sections,
      });
    }
  };

  const handleBlur = (index: number, name: string, callback?: () => void) => {
    formik?.setFieldTouched(`sections[${index}].${name}`, true);
  };

  return (
    <div className="py-10 pl-30 pr-24">
      {disabled ? (
        <div className="relative flex items-center gap-1">
          <h2 className="text-18 font-semibold leading-28 text-white-heading">
            {bio?.title}
          </h2>
          <span className="cursor-pointer">
            <Icon.IcPencil />
          </span>
        </div>
      ) : (
        <>
          {" "}
          <span className="absolute left-[-27px] top-[-2px] flex h-[50px] w-[50px] cursor-grab items-center justify-end xl:left-[-23px]">
            <Icon.IcDrag />
          </span>
          <TextInput
            name="title"
            type="text"
            variant="transparent"
            placeholder="Add a heading"
            value={
              (formik?.values?.sections &&
                formik?.values?.sections[id]?.title) ||
              ""
            }
            onChange={(e: any) => handleChange(e, id)}
            onBlur={(e: any) => {
              handleBlur(id, e?.target.name);
            }}
            errorMessage={getBiographyError(formik, id, "title")}
            showError={
              formik?.touched?.sections
                ? formik?.touched?.sections[id]?.title
                : false
            }
            disabled={disabled || formik?.values?.sections[id]?.disabled}
            errorLabelClass="bio-error"
          />
        </>
      )}
    </div>
  );
};

export default HeadingEditableComponent;
