import { FormikConfig, useFormik } from "formik";
import { SetStateAction } from "react";
import { Dispatch } from "react";

export interface IRegisterInitialValues {
  email: string;
  password: string;
  sections?: IBiography[];
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  dob: string;
  profession: string;
  officeName: string;
  education: string;
  image: string;
}

export interface IBiography {
  id?: any;
  title: string;
  description: string;
  disabled?: any;
}

export interface IContextProps {
  // formik: ReturnType<typeof useFormik<IRegisterInitialValues>> | null;
  formik: any;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isProfileEdit: boolean;
  setIsProfileEdit: Dispatch<SetStateAction<boolean>>;
  deleteId: number | undefined;
  setDeleteId: Dispatch<SetStateAction<number | undefined>>;
}

export function getBiographyError(
  formik: ReturnType<typeof useFormik<IRegisterInitialValues>> | null,
  id: number,
  field: "title" | "description",
): string | null {
  if (
    formik !== null &&
    formik.errors &&
    Array.isArray(formik.errors.sections) &&
    typeof formik.errors.sections[id] !== "string"
  ) {
    const biographyError = formik.errors.sections[id] as IBiography;
    if (biographyError && biographyError[field]) {
      return biographyError[field];
    }
  }
  return null; // Return null if there's no error message
}

export function getBiographyIsTouched(
  formik: ReturnType<typeof useFormik<IRegisterInitialValues>> | null,
  id: number,
  field: "title" | "description",
): string | false {
  if (
    formik !== null &&
    formik.touched &&
    Array.isArray(formik.errors.sections)
  ) {
    //   const biographyError = formik.errors.biography[id] as IBiography;
    //   if (biographyError && biographyError[field]) {
    //     return biographyError[field];
    //   }
    //   const isTouched = formik?.touched?.biography[id]
  }
  return false; // Return null if there's no error message
}
