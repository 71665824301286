import React from 'react'
import { Icon } from 'src/components/atoms/icons'
import { ICard } from './types'
import CountUp from 'react-countup';


const CardComponent = ({title, value = 0}:ICard) => {
    return (
        <div className="bg-card py-16 px-20 rounded-xl w-full">
           <div className="flex justify-between items-center">
                <div>
                    <div className="mb-4 text-white text-40 font-bold leading-60 tracking-0.8">
                    <CountUp end={+value} />
                        </div>
                    <div className="text-white text-14 font-normal leading-24">{title}</div>
                </div>
                <div className="bg-green p-10 rounded-lg">
                    <Icon.IcCardUsersIcon/>
                </div>
           </div>
        </div>
    )
}

export default CardComponent