import React from "react";
import MemberCard from "src/components/molecules/member-card";
import { TODO } from "src/types";

const RelatedProfilesComponent = ({ data = [] }: TODO) => {
  return (
    <div className="main-container">
      <div className="mb-24 flex flex-wrap items-center justify-between gap-2">
        <h2 className="text-24 font-semibold leading-32 text-white-heading">
          See related profiles on PIO-E
        </h2>
      </div>

      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-8">
        {data?.map((val: any, i: any) => (
          <MemberCard
            key={`member_key_${i}`}
            {...val}
            type="default"
            href={`${process.env.REACT_APP_WEBSITE_URL}/members/${val?.slug}`}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedProfilesComponent;
