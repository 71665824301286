
import React from 'react'
import { IRatingCard } from './types'
import CountUp from 'react-countup'
import RatingBar from '../rating-bar';
import { useAppSelector } from 'src/store/hook';
import { userFeedbackDashboardSelector } from 'src/store/features/user-feedback/selectors';


const RatingCardComponent = (props: IRatingCard) => {

    const userFeedbackDashboard = useAppSelector(userFeedbackDashboardSelector)
    
    return (
        <div className="bg-[#1D2939] py-16 px-20 rounded-xl w-full">
            <div className="flex justify-between items-center gap-[22px]">
                <div>
                    <div className="mb-4 text-white text-48 font-normal leading-60">
                        <CountUp end={parseFloat(userFeedbackDashboard?.yourPlatformRate?.average_rating)} decimals={1}/>
                        
                    </div>
                    <div className="text-white text-14 font-normal leading-24">{props?.title}</div>
                </div>
                <div className="w-[60%]">
                    {
                        userFeedbackDashboard?.yourPlatformRate?.percentages?.map((e:any) => {
                             return <RatingBar label={e?.label} value={e?.value}/>
                        })
                    }
               
                 
                </div>
            </div>
        </div>
    )
}

export default RatingCardComponent