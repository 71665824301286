import React from "react";
import { IChip } from "./types";
import { VARIANTS } from "src/types";
import { Link } from "react-router-dom";
import { Icon } from "../icons";
import { truncateString } from "src/utils";

const ChipComponent = ({
  name,
  slug,
  variant = VARIANTS.PRIMARY,
  crossIcon,
}: IChip) => {
  if (variant === VARIANTS.PRIMARY) {
    return (
      <span className="box hover:border-gradient group inline-flex cursor-pointer items-center rounded-lg border bg-gray-300 px-8 py-4  hover:rounded-lg">
        <div className="ellipsis-1 group-hover:gradient-text text-14 font-normal leading-6 text-gradients-linear">
          {name}
        </div>
      </span>
    );
  }
  if (variant === VARIANTS.INFO) {
    return (
      <div className="flex h-[18px] w-[26px] items-center justify-center rounded-xl bg-gray-200">
        <span className="ellipsis-1 text-8 font-medium text-white">{name}</span>
      </div>
    );
  } else {
    return (
      <div className="gradient-border inline-flex items-center gap-2  bg-transparent px-8 py-4">
        <span className="ellipsis-1 gradient-text text-14 font-normal leading-6 text-white-para">
          {name}
        </span>
        {crossIcon}
      </div>
    );
  }
};

export default ChipComponent;
