"use client";
import React from "react";
import ConfirmPublishPostComponent from "./confirm-publish-post.component";
import { IConfirmDeleteModal } from "../../confirm-delete/confirm-delete.component.types";

const ConfirmPublishPostContainer = () => {
  return <ConfirmPublishPostComponent />;
};

export default ConfirmPublishPostContainer;
