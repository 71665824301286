import { setUser } from "src/store/features/auth";
import { IResponse } from "./../types";
import { onQueryStarted, transformErrorResponse } from "./../api.config";
import store from "src/store";
import { IChangeEmail, IChangePassword } from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import { CONSTANTS } from "src/constants";

const authApis = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<
      IResponse,
      Partial<{
        login: string;
        password: string;
        rememberMe: boolean;
        role: any;
      }>
    >({
      query: (body) => ({
        url: ENDPOINTS.AUTH.LOGIN,
        method: "POST",
        body: {
          ...body,
          password: body?.password?.trim(),
          type: CONSTANTS.LOGIN_TYPE.ADMIN,
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        // store.dispatch(setUser(res.data));
        return res;
      },
    }),

    getCurrentUser: build.mutation<IResponse, Partial<any>>({
      query: () => ({
        url: ENDPOINTS.AUTH.GET_CURRENT_USER,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        store.dispatch(setUser(res.data));
        return res;
      },
    }),

    forgotPassword: build.mutation<IResponse, Partial<{ email: string }>>({
      query: (body) => ({
        url: ENDPOINTS.AUTH.FORGOT_PASSWORD,
        method: "POST",
        body: {
          type: CONSTANTS.LOGIN_TYPE.ADMIN,
          ...body,
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        console.log(res, "herer is the repones inside rtk query");
        return res.data;
      },
    }),

    verfifyOtp: build.mutation<
      IResponse,
      Partial<{ code: string; token: any }>
    >({
      query: (body) => ({
        url: ENDPOINTS.AUTH.VERIFY_OTP,
        method: "POST",
        body: {
          type: CONSTANTS.LOGIN_TYPE.ADMIN,
          ...body,
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        console.log(res, "herer is the repones inside rtk query");
        return res.data;
      },
    }),

    verifyPassword: build.mutation<
      IResponse,
      Partial<{ email: string; otp: number | string }>
    >({
      query: (body) => ({
        url: ENDPOINTS.AUTH.VERIFY_OTP,
        method: "POST",
        body,
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
    }),

    resetPassword: build.mutation<
      IResponse,
      Partial<{
        resetToken: string;
        password: any;
        confirm_password: string;
        token: any;
      }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.AUTH.RESET_PASSWORD}?token=${body.token}}`,
        method: "POST",
        body: {
          ...body,
          password: body?.password?.trim(),
          confirm_password: body?.confirm_password?.trim(),
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
    }),

    verifyRecaptcha: build.mutation<IResponse, Partial<{ token: string }>>({
      query: (body) => ({
        url: ENDPOINTS.AUTH.VERIFY_RECAPTCHA,
        method: "POST",
        body,
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
    }),

    changePassword: build.mutation<IResponse, Partial<IChangePassword>>({
      query: (body) => ({
        url: ENDPOINTS.AUTH.CHANGE_PASSWORD,
        method: "PUT",
        body: {
          current_password: body?.currentPassword?.trim(),
          new_password: body?.newPassword?.trim(),
          repeat_password: body?.repeatPassword?.trim(),
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        console.log(res, "herer is the repones inside rtk query");
        return res;
      },
      transformErrorResponse: (res: any) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    changeEmail: build.mutation<IResponse, Partial<IChangeEmail>>({
      query: (body) => ({
        url: ENDPOINTS.AUTH.CHANGE_EMAIL,
        method: "PUT",
        body: {
          email: body?.email,
        },
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        console.log(res, "herer is the repones inside rtk query");
        return res;
      },
      transformErrorResponse: (res: any) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    uploadFile: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        const uniqueFileName = `croppedImage_${Date.now()}.jpg`;
        formData.append("image", body.image, uniqueFileName);

        return {
          url: ENDPOINTS.AUTH.UPLOAD_FILE,
          method: "POST",
          headers: {
            // Set your desired headers here
            // "Content-Type": "multipart/form-data",
            // Add any additional headers as needed
          },
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
    }),
  }),
});
export const {
  useLoginMutation,
  useGetCurrentUserMutation,
  useForgotPasswordMutation,
  useVerifyPasswordMutation,
  useVerfifyOtpMutation,
  useVerifyRecaptchaMutation,
  useChangePasswordMutation,
  useChangeEmailMutation,
  useResetPasswordMutation,
  useUploadFileMutation,
} = authApis;
