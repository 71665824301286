import React, { useEffect } from "react";
import { IEditKeywords } from "./types";
import { useFormik } from "formik";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import EditKeywordsComponent from "./edit-keywords.component";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";

import {
  useEditUserKeywordsMutation,
  useGetUserMutation,
} from "src/services/users";
import { useParams } from "react-router-dom";
import useLoadingError from "src/hooks/useLoadingError";
import { splitKeywords } from "src/components/molecules/crop-modal-easy/utils";

const EditKeywordsContainer = (props: IEditKeywords) => {
  const { loading, startLoading, stopLoading } = useLoadingError();
  const [editUserKeywords] = useEditUserKeywordsMutation();
  const [getUser, { data }] = useGetUserMutation<any>();

  const otherUser = useAppSelector(otherUserSelector);
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      keywords: splitKeywords(otherUser?.keywords),
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      startLoading();
      editUserKeywords({
        keywords: values?.keywords,
        id,
      })
        .unwrap()
        .then((res) => {
          getUser({ id });
          showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
          props?.toggle();
          formik.resetForm();
        })
        .catch((err: any) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        })
        .finally(() => {
          stopLoading();
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    formik?.resetForm();
  }, [props?.isOpen]);

  return <EditKeywordsComponent {...props} formik={formik} loading={loading} />;
};

export default EditKeywordsContainer;
