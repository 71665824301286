import React, { useState, ChangeEvent } from "react";

const ContentLeaderToggleComponent = ({
  user,
  handleToggle,
  checked,
  loading,
}: any) => {
  const [isContentLeader, setIsContentLeader] = useState<boolean>(
    user?.content_leader || false,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = !e.target.checked;
    handleToggle(checked);
    setIsContentLeader(checked);
  };

  return (
    <div className="toggle">
      <input
        onChange={handleChange}
        type="checkbox"
        id={user.id}
        name={user.id}
        checked={!isContentLeader}
        disabled={loading}
      />
      <label htmlFor={user.id}>
        <div className="toggleLabel">
          <span className={`${!isContentLeader ? "text-[#1E1E2C]" : ""}`}>
            No
          </span>
          <span className={`ml-12 ${!isContentLeader ? "" : "text-[#1E1E2C]"}`}>
            Yes
          </span>
        </div>
        <div className="toggler" />
      </label>
    </div>
    // <div className="leader-badge-toggle relative inline-flex w-full items-center overflow-hidden">
    //   <input
    //     onChange={handleChange}
    //     type="checkbox"
    //     id={user.id}
    //     name={user.id}
    //     checked={checked}
    //     disabled={loading}
    //     className="hidden"
    //   />
    //   <label
    //     htmlFor={user.id}
    //     className="relative flex w-full cursor-pointer items-center justify-between overflow-hidden rounded-lg bg-gray-700"
    //   >
    //     <div className="z-10 inline-flex min-h-[36px] w-full min-w-[100px] items-center justify-around p-1 text-center text-xs font-medium text-gray-300 transition-all duration-500">
    //       <span className={checked ? "text-[#1E1E2C]" : ""}>No</span>
    //       <span className={checked ? "" : "text-[#1E1E2C]"}>Yes</span>
    //     </div>
    //     <div className="toggler absolute top-0 h-full w-1/2 rounded-lg bg-gradient-to-b from-[#d06705] via-[#ef9c20] to-[#ecda36] transition-all duration-500" />
    //   </label>
    // </div>
  );
};

export default ContentLeaderToggleComponent;
