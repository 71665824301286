import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import CreateArticleForm from "src/components/organisms/articles/create-article-form";
import PreviewArticleBtn from "src/components/organisms/articles/preview-article-btn";
import PublishArticleBtn from "src/components/organisms/articles/publish-article-btn";
import SaveArticleBtn from "src/components/organisms/articles/save-article-btn";
import PreviewPostBtn from "src/components/organisms/posts/preview-post-btn";
import PublishPostBtn from "src/components/organisms/posts/publish-post-btn";
import SavePostBtn from "src/components/organisms/posts/save-post-btn";
import { topicsSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";
import { PUBLISH_POST_BTN_TYPES } from "src/types";

const CreateArticle = () => {
  const topics = useAppSelector(topicsSelector);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="sticky top-0 z-40 flex flex-wrap items-center justify-between gap-y-4 rounded-tl-lg  rounded-tr-lg bg-gray-400 p-16 ">
        <h3 className="text-24 font-bold leading-8 text-white-heading">
          Create an Article
        </h3>
        <div className="relative flex flex-wrap gap-2">
          <PreviewArticleBtn />
          <SaveArticleBtn />
          <PublishArticleBtn />
        </div>
      </div>
      <CreateArticleForm />
    </PanelLayout>
  );
};

export default CreateArticle;
