import React from 'react'
import AuthLayout from './auth-layout.component'

const AuthLayoutContainer = (props: any) => {


    return (
        <AuthLayout {...props} />
    )
}

export default AuthLayoutContainer
