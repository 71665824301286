"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { useArticlesContext } from "src/context/articles-context";
import useArticle from "src/hooks/useArticle";
import { ARTICLE_ACTIONS } from "src/types";

const PreviewArticleBtnComponent = ({}: any) => {
  const { loading, handleArticleAction } = useArticle();
  const { loading: loadingArticle } = useArticlesContext();

  return (
    <>
      <CustomButton
        title="Preview"
        variant="custom"
        type="button"
        className="relative rounded-xl border border-white-button px-24 py-8 text-14 font-medium leading-20 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none"
        onClick={() => handleArticleAction(ARTICLE_ACTIONS.PREVIEW)}
        loading={loading}
        disabled={loadingArticle}
      />
    </>
  );
};

export default PreviewArticleBtnComponent;
