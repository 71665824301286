import React, { useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { ACTION_TYPE, TOASTR_TYPES } from "src/types";
import CustomButton from "src/components/atoms/button";

import AddTopicForm from "../add-topic-form";
import { useNavigate } from "react-router-dom";
import {
  useDeleteTopicMutation,
  useGetTopicsListingMutation,
} from "src/services/topics";

const DataTableContainer = () => {
  const [getListing] = useGetTopicsListingMutation();
  const [deleteTopic] = useDeleteTopicMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({ key: "topics-datatable", mutation: getListing });

  const navigate = useNavigate();

  // for delete popup
  const { isOpen, toggle } = useModal();

  // for add topic
  const { isOpen: isOpenAddTopic, toggle: toggleAddTopic } = useModal();

  const [deleteId, setDeleteId] = useState<number | null>(null);

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setDeleteId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteTopic({ id: deleteId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onRowClicked = (row: any) => {
    navigate(`/admin/topics/${row?.id}`);
  };

  return (
    <>
      <DataTableComponent
        title={"Topics"}
        description={`${
          pagination?.total == 0
            ? "No topics"
            : pagination?.total > 1
              ? `Total ${pagination?.total} topics`
              : `Total ${pagination?.total} topic`
        } `}
        className=""
        data={data}
        columns={columns(showDeletePopup)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={onRowClicked}
        clearSearch={clearSearch}
        button={
          <CustomButton
            title="Add topic"
            variant="primary"
            onClick={toggleAddTopic}
          />
        }
      />

      <ConfirmDelete
        title="Are you sure you want to remove this topic?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />

      <AddTopicForm
        isOpen={isOpenAddTopic}
        toggle={toggleAddTopic}
        fetchData={() => fetchData({ currentPage: 1, query: "" })}
        actionType={ACTION_TYPE.ADD}
        initialValues={{
          title: "",
          description: "",
          keywords: [],
        }}
      />
    </>
  );
};

export default DataTableContainer;
