import React from "react";
import { IRatingStars } from "./types";
import {  Rate } from "antd";
import "./rating-stars.style.css"

const RatingStarsComponent = (props: IRatingStars) => {
  return (
    <div className="table-rating">
        <Rate
          // allowHalf
          disabled
          value={parseFloat(props?.value)}
          className="rounded-[40px] py-4 px-8 bg-gray-200 inline-flex items-center text-[#D0D5DD] !text-16"
        />
    </div>
  );
};

export default RatingStarsComponent;
