import { usePostsContext } from "src/context/posts-context";
import { POST_TYPES } from "src/types";
import React from "react";

const FilterOptionComponent = ({ value, title, onClick }: any) => {
  const { filter } = usePostsContext();
  return (
    <li>
      <span
        className={` flex items-center gap-2 py-12 pl-12 pr-16 ${filter == value ? "bg-filter-active" : "white-link-with-icon"}`}
        onClick={onClick}
      >
        <span className="whitespace-nowrap text-14 font-normal leading-20 text-white-heading">
          {title}
        </span>
      </span>
    </li>
  );
};

export default FilterOptionComponent;
