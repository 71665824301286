"use client";
import ImageUpload from "src/components/atoms/image-upload";
import SelectInput from "src/components/atoms/select-input";
import TextInput from "src/components/atoms/text-input";
import TextArea from "src/components/atoms/textarea";

import React, { useEffect, useState } from "react";
import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import SectionsEditable from "src/components/organisms/sections/sections-editable";
import { usePostsContext } from "src/context/posts-context";
import { POST_TYPES, TODO } from "src/types";
import useQueryParams from "src/hooks/useQueryParams";

const CreatePostFormComponent = ({ topics, isEdit, initialData }: TODO) => {
  const { getQueryParam } = useQueryParams();
  const section = getQueryParam("section");
  const [disabled, setDisabled] = useState();

  const { formik } = usePostsContext();

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  useEffect(() => {
    formik.setValues(
      isEdit
        ? {
            id: initialData?.id,
            status: initialData?.status,
            slug: initialData?.slug,
            topic_id: initialData?.topic_id,
            title: initialData?.title,
            subtitle: initialData?.subtitle,
            description: initialData?.description,
            image: initialData?.image,
            sections: initialData?.content?.map(
              ({ id, title, description }: any) => ({
                id,
                title,
                description,
              }),
            ),
          }
        : {
            status: POST_TYPES.DRAFT,
            slug: "",
            topic_id: "",
            title: "",
            subtitle: "",
            description: "",
            image: "",
            sections: [
              {
                title: "",
                description: "",
                className: "add-section-min-height",
              },
            ],
          },
    );
  }, [initialData, isEdit]);

  useEffect(() => {
    let interval = setInterval(() => {
      const element: any = document.getElementById(section);

      if (element) {
        const headerHeight: any = document.getElementById("top")
          ? document.getElementById("top")?.offsetHeight
          : "";

        // Calculate the top position considering the sticky header
        const topPosition =
          element?.getBoundingClientRect().top +
          window.pageYOffset -
          (headerHeight + 170);

        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });

        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {
    console.log(
      topics?.map((topic: any) => ({
        value: topic?.id,
        label: topic?.title,
      })),
    );
  }, [topics]);

  return (
    <>
      <div className="default-section-template h-full p-16">
        <div className="flex flex-col gap-y-8">
          <SelectInput
            name="topic"
            label="Select topic"
            value={formik?.values?.topic_id ? formik?.values?.topic_id : []}
            showError={Boolean(formik?.touched.topic_id)}
            errorMessage={formik?.errors.topic_id}
            options={topics
              ?.map((topic: any) => ({
                value: topic?.id,
                label: topic?.title,
              }))
              .slice()
              .sort((a: any, b: any) => a.label.localeCompare(b.label))}
            onSelect={(value: string) =>
              formik?.setFieldValue("topic_id", value)
            }
            containerClassName="theme-form-select "
            required={true}
            placeholder="Select a topic"
            searchable={true}
          />
          <TextInput
            name="title"
            type="text"
            label="Title"
            containerClassName=""
            value={formik?.values.title}
            showError={Boolean(formik?.touched.title)}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.title}
            required={true}
            placeholder="Enter your title here"
          />
          <TextInput
            name="subtitle"
            type="text"
            label="Subtitle"
            containerClassName=""
            value={formik?.values.subtitle}
            showError={Boolean(formik?.touched.subtitle)}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            errorMessage={formik?.errors.subtitle}
            required={false}
            placeholder="Enter your subtitle here"
          />

          <TextArea
            name="description"
            label="Description"
            value={formik?.values.description}
            showError={Boolean(formik?.touched.description)}
            onChange={(e: TODO) => {
              formik?.handleChange(e);
            }}
            onBlur={(e: TODO) => {
              formik?.handleBlur(e);
            }}
            errorMessage={formik?.errors.description}
            autoFocus={false}
            required={true}
            placeholder="Enter your description here.."
          />
          <div className="flex">
            {/* <Label id="" name="" required={true}/> */}
            <ImageUpload
              label="Upload post image"
              name="image"
              value={formik?.values?.image}
              image={formik?.values?.image}
              className="!h-[240px]  !w-[240px] bg-gray-400 "
              onChange={(img: string) => formik?.setFieldValue("image", img)}
              onDelete={() => formik?.setFieldValue("image", "")}
              showError={Boolean(formik?.touched.image)}
              errorMessage={formik?.errors.image}
              setDisabled={setDisabled}
              formik={formik}
              isOpenModal={true}
              required={false}
              useCropper={true}
              disableAspectRatio={false}
            />
          </div>
          <SectionsEditable
            formik={formik}
            saveOnFocusOut={true}
            saveOnDragEnd={true}
            handleBlur={() => {}}
            contentType={CONTENT_TYPE.POST}
            profile={null}
            showHeader={false}
          />
        </div>
      </div>
    </>
  );
};

export default CreatePostFormComponent;
