import React from "react";
import { Icon } from "src/components/atoms/icons";

export const columns = (showDeletePopup: any) => {
   return [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row?.name,
            sortable: true,
            maxWidth: '1000px',
        },
        {
            name: '',
            cell: (row: any) =>  {
                 return  <>
                 <div className="flex items-center justify-end">
                    <button className="p-12 cursor-pointer" onClick={() => showDeletePopup(row?.id)}>
                        <Icon.IcDelete />
                    </button>
                 </div>
             </>
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
}

