
import React from 'react'
import { Icon } from '../icons'
import { ILogoutButton } from './types'

const LogoutButtonComponent = ({handleLogout}: ILogoutButton) => {

    return (
        <span className="pl-16 py-12 pr-16 flex items-center gap-2 white-link-with-icon" onClick={handleLogout}>
            <Icon.IcLogoutAdmin />
            <span className="text-white-heading text-16 font-normal leading-24 whitespace-nowrap">Log out</span>
        </span>
    )
}

export default LogoutButtonComponent