import React from "react";
import { Media } from "react-data-table-component";
import { Icon } from "src/components/atoms/icons";


export const columns = (showDeletePopup: any,setItemId: any,toggle: any, user: any) => {
  return [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      width: window?.innerWidth < 567 ?'200px' :'582px',
    },
    {
      name: "Email Address",
      selector: (row: any) => row.email,
      sortable: true,
      width: '265px',
    },
    {
      name: "Role",
      selector: (row: any) => <p className="capitalize">{row.role}</p>,
      sortable: true,
      width: '172px',
    },
    {
      name: "",
      cell: (row: any) => {
        console.log(row,"row")
        return (
          <>
          <div className={`${user?.id == row?.id ? "invisible" :""}`}>
            <div className="flex items-center justify-end">
              <button className="p-12" onClick={() =>{
                setItemId(row?.id)
                toggle()
              }}>
                <Icon.IcEdit />
              </button>
              <button
                className="p-12 cursor-pointer"
                onClick={() => showDeletePopup(row?.id)}
              >
                <Icon.IcDelete />
              </button>
            </div>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
};
