import React from 'react'
import PanelLayout from 'src/components/layouts/panel-layout'
import DataTable from './elements/data-table'

const AdminUsers = () => {
  return (
    <>
          <PanelLayout wrapperClass ={`px-24 py-20`}>
                {/* <DatePicker /> */}
                {/* <div className='text-yellow-700'>  This is Featured Members page</div> */}
                <DataTable/>
            </PanelLayout>
     </>
  )
}

export default AdminUsers