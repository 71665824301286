"use client";
import React from "react";
import { IMemberCard } from "./member-card.types";

import { countries } from "src/lib/country";
import { Link } from "react-router-dom";
import {
  capitalizeFirstLetter,
  extractTextFromHTML,
  truncateString,
} from "src/utils";
import { Icon } from "src/components/atoms/icons";
import LeaderBadge from "src/components/atoms/leader-badge";
const MemberCardComponent = ({
  id,
  slug,
  name,
  profession,
  country,
  image,
  description,
  type = "default",
  is_verify,
  office_name,
  href = "",
  content_leader,
}: IMemberCard) => {
  description = extractTextFromHTML(description);

  if (type == "default") {
    return (
      <div className="relative bg-gray-400">
        <Link
          to={href}
          className="absolute z-10 h-full w-full text-[0]"
          target="_blank"
        >
          {name}
        </Link>
        <div className="relative w-full shrink-0">
          <img
            className="h-full w-full"
            src={image || "../../../assets/images/Profile.svg"}
            width={100}
            height={100}
            alt="profileimage-1e"
            placeholder="blur"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            title={name}
            loading="eager"
          />
        </div>
        <div className="flex flex-col justify-between px-16 pb-16 pt-16 sm:px-24 sm:pb-20">
          <h2 className="first-letter-capital flex items-center text-18 font-semibold leading-28 text-white-heading">
            <span className="ellipsis-1">{capitalizeFirstLetter(name)}</span>
            {is_verify && (
              <span className="ml-2 flex-shrink-0 md:ml-4">
                <Icon.IcVerified />
              </span>
            )}
          </h2>
          <p className="first-letter-capital mb-8 truncate text-14 font-medium leading-20 text-white-para">
            {profession} &nbsp;{office_name ? `@ ${office_name}` : ""}
          </p>
          {Boolean(content_leader) && (
            <div className="mb-8 mt-4">
              <LeaderBadge />
            </div>
          )}
          <div className="mb-8 flex max-w-fit items-center rounded border border-blue-link py-5 pl-6 pr-8">
            <div className="relative mr-8 h-[20px] w-[20px] shrink-0 rounded-full">
              <img
                className="absolute inset-0 h-[20px] w-[20px] max-w-full rounded-full border-[1.5px] border-[#EDEDED] object-cover"
                src={countries[country]?.image}
                alt="flag icon"
                title={country}
                height={19}
                width={19}
                loading="eager"
              />
            </div>
            <div className="truncate text-12 font-normal leading-16 tracking-0.24 text-blue-link">
              {countries[country]?.name}
            </div>
          </div>
          <p className="ellipsis-2 text-16 font-normal leading-24 text-white-para">
            {extractTextFromHTML(description)}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      // <div>
      //   <div className="relative mr-24">
      //     <Link href={href} className="absolute z-10 h-full w-full text-[0]">
      //       {name}
      //     </Link>
      //     <div className="flex flex-col gap-6 bg-gray-400 p-16 xl:flex-row">
      //       <div className="relative w-full shrink-0 xl:w-[150px]">
      //         <Image
      //           className="h-full w-full"
      //           src={image || "../../assets/images/Profile.svg"}
      //           width={100}
      //           height={100}
      //           objectFit="cover"
      //           alt="Featured Member Image"
      //           placeholder="blur"
      //           blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPcXw8AAgMBQLfkYc4AAAAASUVORK5CYII="
      //           sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      //           title={name}
      //           loading="eager"
      //         />
      //       </div>
      //       <div className="flex flex-col">
      //         <h2 className="ellipsis-1 first-letter-capital break-all text-18 font-semibold leading-28 text-white-heading">
      //           {capitalizeFirstLetter(name)}
      //         </h2>
      //         <p className="ellipsis-1 first-letter-capital mb-8 break-all text-14 font-medium leading-20 text-white-para">
      //           {profession}
      //         </p>
      //         <div className="mb-8 flex max-w-fit items-center rounded border border-blue-link py-5 pl-6 pr-8">
      //           <div className="relative mr-8 h-[20px] w-[20px] shrink-0 rounded-full">
      //             <Image
      //               className="absolute inset-0 h-[20px] w-[20px] max-w-full rounded-full border-[1.5px] border-[#EDEDED] object-cover"
      //               src={countries[country]?.image}
      //               alt="flag icon"
      //               objectFit="cover"
      //               title={country}
      //               height={19}
      //               width={19}
      //               loading="eager"
      //             />
      //           </div>
      //           <div className="ellipsis-1 break-all text-12 font-normal leading-16 tracking-0.24 text-blue-link">
      //             {countries[country]?.name}
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div>
        <div className="relative">
          <Link
            to={href}
            className="absolute z-10 h-full w-full text-[0]"
            target="_blank"
          >
            {name}
          </Link>
          <div className="flex flex-col gap-4 bg-gray-400 p-16 ">
            <div className="flex gap-6">
              <div className="relative h-[88px] w-[88px] shrink-0">
                <img
                  className="h-full w-full"
                  src={image || "../../../assets/images/Profile.svg"}
                  width={88}
                  height={88}
                  alt="Featured Member Image"
                  placeholder="blur"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  title={name}
                  loading="eager"
                />
              </div>
              <div className="flex flex-col">
                <h2 className="first-letter-capital flex items-center text-18 font-semibold leading-28 text-white-heading">
                  <span className="ellipsis-1">
                    {capitalizeFirstLetter(name)}
                  </span>
                  {is_verify && (
                    <span className="ml-2 flex-shrink-0 md:ml-4">
                      <Icon.IcVerified />
                    </span>
                  )}
                </h2>
                <p className="ellipsis-1 first-letter-capital mb-8 break-all text-14 font-medium leading-20 text-white-para">
                  {profession} &nbsp;
                  {office_name ? `@ ${office_name}` : ""}
                </p>
                {Boolean(content_leader) && (
                  <div className="mb-8">
                    <LeaderBadge />
                  </div>
                )}
                <div className="mb-8 flex max-w-fit items-center rounded border border-blue-link py-5 pl-6 pr-8">
                  <div className="relative mr-8 h-[20px] w-[20px] shrink-0 rounded-full">
                    <img
                      className="absolute inset-0 h-[20px] w-[20px] max-w-full rounded-full border-[1.5px] border-[#EDEDED] object-cover"
                      src={countries[country]?.image}
                      alt="flag icon"
                      title={country}
                      height={19}
                      width={19}
                      loading="eager"
                    />
                  </div>
                  <div className="ellipsis-1 break-all text-12 font-normal leading-16 tracking-0.24 text-blue-link">
                    {countries[country]?.name}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="ellipsis-2 text-16 font-normal leading-24 text-white-para">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MemberCardComponent;
