export interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
  disabled?: any;
}

export interface ITab {
  title: string;
  component?: React.ReactElement;
  count?: number;
}

export interface IModal {
  children?: React.ReactElement;
  className?: string;
}

export interface IModalState {
  children?: React.ReactElement;
  isOpen: boolean;
  showCloseBtn?: boolean;
  toggle?: () => void;
  openModal?: () => void;
  closeModal?: () => void;
}

export interface IWorker {
  toggle: () => void;
  deleteId?: number | undefined;
  setDeleteId: React.Dispatch<React.SetStateAction<undefined>>;
  deleteSuccess: boolean;
}

export interface IPaginationData {
  id?: number | string | undefined;
  limit: number;
  page: number;
  keyword: string;
}

export enum USER_ROLES {
  ADMIN = "ADMIN",
  BRAND = "BRAND",
  USER = "USER",
  FACTORY_WORKER = "FACTORY_WORKER",
  SALES_PERSON = "SALES_PERSON",
}

export enum TOASTR_TYPES {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export enum REQUEST_STATUS {
  PENDING = "1",
  APPROVE = "2",
  REJECT = "3",
}

export interface IBiography {
  id?: any;
  title: string;
  description: string;
}

export enum DRAWER_TYPES {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_EMAIL = "CHANGE_EMAIL",
}

export interface IDataTable {
  title: string;
  description: string;
  className?: string;
  data: any;
  columns: any;
  showHeader: boolean;
  showFooter: boolean;
  pagination: any;
  handleSearch: any;
  clearSearch: any;
  handlePageChange: any;
  handleItemsPerPageChange: any;
  onRowClicked?: any;
  header?: any;
  footer?: any;
  button?: any;
}

export enum VARIANTS {
  "PRIMARY" = "primary",
  "SECONDARY" = "secondary",
  "INFO" = "info",
}

export enum ACTION_TYPE {
  ADD = "ADD",
  EDIT = "EDIT",
}

export enum POST_TYPES {
  "PUBLISH" = "publish",
  "DRAFT" = "draft",
  "ALL" = "all",
}

export enum PUBLISH_POST_BTN_TYPES {
  DEFAULT = "DEFAULT",
  CARD = "CARD",
}

export enum POST_ACTIONS {
  PREVIEW = "PREVIEW",
  SAVE = "SAVE",
  PUBLISH = "PUBLISH",
}

export enum ARTICLE_ACTIONS {
  PREVIEW = "PREVIEW",
  SAVE = "SAVE",
  PUBLISH = "PUBLISH",
}

export enum ARTICLE_STATUS {
  DRAFT = "draft",
  PUBLISH = "publish",
}

export enum ARTICLE_TYPES {
  PEOPLE = "people",
  TOPIC = "topic",
}

export type TODO = any;
