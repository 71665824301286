import useLoadingError from "./useLoadingError";
import { POST_ACTIONS, POST_TYPES, TOASTR_TYPES } from "src/types";

import { useAuthContext } from "src/context/auth-context";
// import { useRouter } from "next/navigation";
import { toast } from "react-toastify";
import { showToast } from "src/utils";
import { usePostsContext } from "src/context/posts-context";
import { checkIfProfileHiddenByAdmin } from "src/helpers";
import { usePublishPostMutation } from "src/services/posts";
import { scrollToError } from "src/components/molecules/crop-modal-easy/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";

const usePost = () => {
  //   const router = useRouter();

  const [publishPost] = usePublishPostMutation();
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const otherUser = useAppSelector(otherUserSelector);

  const {
    formik,
    createUpdatePost,
    setMyPosts,
    setFilter,
    setPageMyPosts,
    fetchDataMyPosts,
    toggleBecomeMember,
    setPublishPostId,
  } = usePostsContext();
  const { startLoading, stopLoading, loading } = useLoadingError();

  // const handlePostAction = (postAction: POST_ACTIONS) => {
  //   formik.submitForm();
  //   if (!formik?.isValid) {
  //     return scrollToError();
  //   }
  //   if (
  //     postAction === POST_ACTIONS.PREVIEW ||
  //     postAction === POST_ACTIONS.SAVE
  //   ) {
  //     startLoading();
  //     createUpdatePost(POST_TYPES.DRAFT, userId)
  //       .then((res: any) => {
  //         if (postAction === POST_ACTIONS.SAVE) {
  //           navigate(`/admin/users/${userId}?activeTab=2`);
  //           showToast(
  //             toast,
  //             TOASTR_TYPES.SUCCESS,
  //             "Your post has been saved successfully",
  //           );
  //         } else {
  //           navigate(
  //             `/admin/users/${userId}/post/${res?.data?.id}?action=preview`,
  //           );
  //         }
  //       })
  //       .finally(() => {
  //         stopLoading();
  //       });
  //   } else if (postAction === POST_ACTIONS.PUBLISH) {
  //     if (checkIfProfileHiddenByAdmin(otherUser)) {
  //       return;
  //     }
  //     if (
  //       otherUser?.is_publish == 1 ||
  //       otherUser?.admin_publish_hidden == "Published"
  //     ) {
  //       startLoading();
  //       createUpdatePost(POST_TYPES.PUBLISH, userId)
  //         .then((res: any) => {
  //           navigate(`/admin/users/${userId}?activeTab=2`);
  //           showToast(
  //             toast,
  //             TOASTR_TYPES.SUCCESS,
  //             "Your post has been published successfully",
  //           );
  //         })
  //         .finally(() => {
  //           stopLoading();
  //         });
  //     }
  //   }
  // };

  const handlePostAction = (postAction: POST_ACTIONS) => {
    return new Promise((resolve, reject) => {
      formik.submitForm();

      if (!formik?.isValid) {
        scrollToError();
        return reject("Form validation failed");
      }

      if (
        postAction === POST_ACTIONS.PREVIEW ||
        postAction === POST_ACTIONS.SAVE
      ) {
        startLoading();
        createUpdatePost(POST_TYPES.DRAFT, userId)
          .then((res: any) => {
            if (postAction === POST_ACTIONS.SAVE) {
              navigate(`/admin/users/${userId}?activeTab=2`);
              showToast(
                toast,
                TOASTR_TYPES.SUCCESS,
                "Your post has been saved successfully",
              );
            } else {
              navigate(
                `/admin/users/${userId}/post/${res?.data?.id}?action=preview`,
              );
            }
            resolve(res);
          })
          .catch((error: any) => reject(error))
          .finally(() => stopLoading());
      } else if (postAction === POST_ACTIONS.PUBLISH) {
        if (checkIfProfileHiddenByAdmin(otherUser)) {
          return reject("Profile is hidden by admin");
        }

        if (
          otherUser?.is_publish == 1 ||
          otherUser?.admin_publish_hidden == "Published"
        ) {
          startLoading();
          createUpdatePost(POST_TYPES.PUBLISH, userId)
            .then((res: any) => {
              navigate(`/admin/users/${userId}?activeTab=2`);
              showToast(
                toast,
                TOASTR_TYPES.SUCCESS,
                "Your post has been published successfully",
              );
              resolve(res);
            })
            .catch((error: any) => reject(error))
            .finally(() => stopLoading());
        } else {
          showToast(
            toast,
            TOASTR_TYPES.ERROR,
            "Please publish the user's profile before publishing a post",
          );
          return reject("Profile not published");
        }
      }
    });
  };

  const handlePublishPost = (postId: any) => {
    return new Promise((reject, resolve) => {
      if (checkIfProfileHiddenByAdmin(otherUser)) {
        return reject("Profile hiden");
      }
      if (
        otherUser?.is_publish == 1 ||
        otherUser?.admin_publish_hidden == "Published"
      ) {
        publishPost({ id: postId })
          .unwrap()
          .then((res: any) => {
            setMyPosts([]);
            setFilter(POST_TYPES.ALL);
            fetchDataMyPosts({ page: 1, filter: POST_TYPES.ALL, userId });
            setPageMyPosts(2);
            showToast(
              toast,
              TOASTR_TYPES.SUCCESS,
              "Your post has been published successfully",
            );
            resolve(res);
          })
          .catch((err: any) => {
            reject(err);
          });
      } else {
        showToast(
          toast,
          TOASTR_TYPES.ERROR,
          "Please publish the user's profile before publishing a post",
        );
        return reject("Profile not published");
      }
    });
  };
  return {
    loading,
    handlePostAction,
    handlePublishPost,
  };
};

export default usePost;
