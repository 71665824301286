import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";
import Keywords from "src/components/molecules/keywords";
import { getUserProfileStatus } from "src/helpers";
import { countries } from "src/lib/country";
import { ARTICLE_STATUS } from "src/types";
import { capitalizeFirstLetter } from "src/utils";

export const columns = (showDeletePopup: any) => {
  return [
    {
      name: "Title",
      selector: (row: { title: string }) => row?.title,
      sortable: true,
      width: "489px",
      // width: "132px",
    },
    {
      name: "Type",
      selector: (row: { type: string }) =>
        capitalizeFirstLetter(
          row?.type == "topic" ? "Product / Company" : "people",
        ),
      sortable: false,
      width: "232px",
    },
    {
      name: "Published",
      selector: (row: { status: string }) =>
        row?.status === ARTICLE_STATUS.PUBLISH ? "Yes" : "No",
      sortable: false,
      width: "200px",
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <>
            <div className="flex items-center justify-end">
              <Link to={`/admin/library/article/${row?.id}/edit`}>
                <button className="cursor-pointer p-12">
                  <Icon.IcEdit />
                </button>
              </Link>
              <button
                className="cursor-pointer p-12"
                onClick={() => showDeletePopup(row?.id)}
              >
                <Icon.IcDelete />
              </button>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
};
