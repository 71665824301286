"use client";

import React from "react";
import Header from "./header";
import AddSectionButton from "./add-section";
import AllSections from "./all-sections";
import { ISectionsEditable } from "./types";

const SectionsEditableComponent = ({
  className,
  showHeader = false,
  formik,
  saveOnFocusOut = false,
  saveOnDragEnd = false,
  handleBlur,
  contentType,
}: ISectionsEditable) => {
  return (
    <>
      {/* <div className="default-section-template h-full"> */}
      <div className="default-section-template">
        {showHeader && <Header />}
        <div className={className}>
          <AllSections
            formik={formik}
            saveOnFocusOut={saveOnFocusOut}
            saveOnDragEnd={saveOnDragEnd}
            handleBlur={handleBlur}
            contentType={contentType}
          />
          {/* add more section button */}
          <div className="lg:mb-30">
            <AddSectionButton formik={formik} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionsEditableComponent;
