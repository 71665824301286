"use client";
import React from "react";
import { useRegisterContext } from "src/context/register-context";
import { Icon } from "src/components/atoms/icons";
import { useEditorContext } from "src/context/editor-context";

const AddSectionComponent = ({ formik }: any) => {
  const { handleAddSection } = useEditorContext();

  return (
    <div className="flex items-center justify-center">
      <button
        onClick={() => handleAddSection(formik)}
        className="blue-link-with-icon flex items-center gap-2"
      >
        <Icon.IcPlus />{" "}
        <span className="text-18 font-normal leading-28 text-blue-link">
          Add Section
        </span>
      </button>
    </div>
  );
};

export default AddSectionComponent;
