import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "src/components/atoms/icons";
import ContentLeaderToggle from "src/components/molecules/content-leader-toggle";
import Keywords from "src/components/molecules/keywords";
import { getUserProfileStatus } from "src/helpers";
import { countries } from "src/lib/country";

export const columns = (showDeletePopup: any) => {
  return [
    {
      name: "Name",
      selector: (row: { name: string }) => row.name,
      sortable: true,
      width: "132px",
    },
    {
      name: "Email Address",
      selector: (row: { email: string }) => row.email,
      sortable: false,
      width: "232px",
    },
    {
      name: "Profession",
      selector: (row: { occupation: string }) =>
        row.occupation || <p className="">&ndash;</p>,
      sortable: false,
      // width: "200px",
    },
    {
      name: "Keywords",
      selector: (row: { keywords: string; id: string }) => (
        <Link
          to={`/admin/users/${row?.id}`}
          className="relative z-10 flex gap-3"
        >
          {!row?.keywords ? (
            <p className="">&ndash;</p>
          ) : (
            <div className="flex gap-3">
              <Keywords data={row?.keywords} />
            </div>
          )}
        </Link>
      ),
      sortable: false,
      // width: "232px",
    },
    {
      name: "Country",
      selector: (row: { country: string }) =>
        countries[row.country]?.name || <p className="">&ndash;</p>,
      sortable: false,
      width: "124px",
    },
    {
      name: "Status",
      selector: (row: { is_publish: string; admin_publish_hidden: string }) =>
        row.admin_publish_hidden
          ? row.admin_publish_hidden
          : row?.is_publish
            ? "Published"
            : "Hidden",
      sortable: false,
      width: "",
    },
    {
      name: "No. of posts",
      selector: (row: { posts_count: string }) => row?.posts_count,
      sortable: false,
      width: "",
    },
    {
      name: "Content Leader",
      selector: (row: { content_leader: string }) => (
        <ContentLeaderToggle user={row} />
      ),
      sortable: false,
      width: "",
    },
    {
      name: "",
      cell: (row: any) => {
        return (
          <>
            <div className="flex items-center justify-end">
              <Link to={`/admin/users/${row?.id}`}>
                <button className="cursor-pointer p-12">
                  <Icon.IcView />
                </button>
              </Link>
              <button
                className="cursor-pointer p-12"
                onClick={() => showDeletePopup(row?.id)}
              >
                <Icon.IcDelete />
              </button>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
};
