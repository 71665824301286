import React, { useEffect } from 'react'
import PanelLayout from 'src/components/layouts/panel-layout'
import DataTable from './elements/data-table'
import Card from 'src/components/molecules/cards'
import RatingCard from './elements/rating-card'
import { useGetUserFeedbackDashboardMutation } from 'src/services/user-feedback'
import { userFeedbackDashboardSelector } from 'src/store/features/user-feedback/selectors'
import { useAppSelector } from 'src/store/hook'

const UserFeedback = () => {

  const [getUserFeedbackDashboard] = useGetUserFeedbackDashboardMutation();
  
  const userFeedbackDashboard =  useAppSelector(userFeedbackDashboardSelector)

  useEffect(() => {
    getUserFeedbackDashboard({})
  },[]);




  return (
    <>
      <PanelLayout wrapperClass ={`px-24 py-20`}>
        <div className="flex items-center gap-[23px] pb-12 flex-wrap xl:flex-nowrap mb-20">
            <Card title='Total User Feedback' value={userFeedbackDashboard?.totalFeedback}/>
            <RatingCard title="Your Platform Rate"/>
        </div>
        
       <DataTable/>
      </PanelLayout>
    </>
  )
}

export default UserFeedback