import React from 'react'
import DatetableHeaderComponent from './datatable-header.component'
import { IDataTable } from 'src/types'

const DatatableContainer = (props: Partial<IDataTable>) => {
    return (
        <DatetableHeaderComponent {...props} />
    )
}

export default DatatableContainer