import React from "react";
import { Link } from "react-router-dom";
import Chip from "src/components/atoms/chip";
import { Icon } from "src/components/atoms/icons";
import Keywords from "src/components/molecules/keywords";
import { VARIANTS } from "src/types";

export const columns = (showDeletePopup: any) => {
  return [
    {
      name: "Title",
      selector: (row: { title: string }) => row.title,
      sortable: true,
      width: "203px",
    },
    {
      name: "Description",
      selector: (row: { description: string }) => row.description,
      sortable: false,
      width: "203px",
    },
    {
      name: "Keywords",
      selector: (row: { id: string; keywords: string }) => (
        <Link
          to={`/admin/topics/${row?.id}`}
          className="relative z-10 flex gap-3"
        >
          <Keywords data={row.keywords} />
        </Link>
      ),
      sortable: false,
      width: "203px",
    },
    {
      name: "Related posts",
      selector: (row: { related_posts_count: string }) =>
        row?.related_posts_count,
      sortable: false,
      width: "203px",
    },
    {
      name: "Views",
      selector: (row: { views_count: string }) => row?.views_count,
      sortable: false,
      width: "203px",
    },

    {
      name: "",
      cell: (row: any) => {
        return (
          <>
            <div className="flex items-center justify-end">
              <Link to={`/admin/topics/${row?.id}`}>
                <button className="cursor-pointer p-12">
                  <Icon.IcView />
                </button>
              </Link>
              <button
                className="cursor-pointer p-12"
                onClick={() => showDeletePopup(row?.id)}
              >
                <Icon.IcDelete />
              </button>
            </div>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
};
