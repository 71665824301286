import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "src/components/atoms/text-input";
import PanelLayout from "src/components/layouts/panel-layout";
import { useGetFeedbackMutation } from "src/services/user-feedback";
import RatingStars from "src/components/atoms/rating-stars";
import { capitalizeFirstLetter, showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";

const ViewUserFeedback = () => {
  const { id } = useParams();
  const [getFeedback, { data }] = useGetFeedbackMutation();
  const navigate = useNavigate();

  useEffect(() => {
    getFeedback({ id });
  }, []);

  const handleTextareaResize = () => {
    const textarea = document.getElementById("text-area-feedback"); // Replace with your actual ID
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 20}px`;
    }
  };

  const viewUserProfile = () => {
    let toastId;
    if (data?.data?.is_user_deleted) {
      toast.dismiss(toastId);
      toastId = showToast(
        toast,
        TOASTR_TYPES.WARNING,
        "This user account does not currently exist on the platform",
        "Alert!"
      );
    } else {
      navigate(`/admin/users/${data?.data?.user_id}?activeTab=1`);
    }
  };

  useEffect(() => {
    handleTextareaResize();
  }, [data?.data?.feedback]);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="grid xl:grid-cols-12 gap-5">
        <div className="xl:col-span-8 xxl:col-span-9 order-2 xl:order-1 block">
          <div className="bg-gray-400 rounded-xl">
            <div className="p-24 border-b border-border-color">
              <h4 className="text-white-heading text-16 font-semibold leading-24">
                Feedback
              </h4>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <div className="">
                  <label className="mb-6 text-white-heading text-14 leading-20 font-medium block">
                    Rate
                  </label>
                  <div className="relative">
                    <RatingStars value={data?.data?.rate} />
                  </div>
                </div>
                <TextInput
                  name="email"
                  type="email"
                  label="Title"
                  value={data?.data?.title}
                  disabled={true}
                />
                <div className="">
                  <label className="mb-6 text-white-heading text-14 leading-20 font-medium block">
                    Feedback
                  </label>
                  <div className="relative">
                    <textarea
                      className="w-full px-16 py-11 rounded border border-border-color bg-input-background text-16 leading-24 font-normal text-placeholder-text-color cursor-not-allowed resize-none max-h-[500px] overflow-y-auto"
                      value={data?.data?.feedback}
                      id="text-area-feedback"
                      onChange={handleTextareaResize}
                      disabled={true}
                    >
                      {data?.data?.feedback}
                    </textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="xl:col-span-4 xxl:col-span-3 order-1 xl:order-2">
          <div className="bg-gray-400 rounded-xl">
            <div className="p-24 border-b border-border-color flex items-center justify-between flex-wrap gap-2">
              <h4 className="text-white-heading text-16 font-semibold leading-24">
                Sender
              </h4>
              <span
                onClick={viewUserProfile}
                className="inline-block text-white-heading text-16 font-semibold leading-24 cursor-pointer"
              >
                See profile
              </span>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <TextInput
                  name="email"
                  type="email"
                  label="Name"
                  value={data?.data?.user_name}
                  disabled={true}
                />
                <TextInput
                  name="email"
                  type="email"
                  label="Role"
                  value={capitalizeFirstLetter(data?.data?.user_role)}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </PanelLayout>
  );
};

export default ViewUserFeedback;
