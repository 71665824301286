import useLoadingError from 'src/hooks/useLoadingError'
import React, { useState } from 'react'
import VerifyOtpForm from './verify-otp-form.component'
import { useVerfifyOtpMutation } from 'src/services/auth'
import { useSearchParams, useNavigate } from 'react-router-dom';


const VerifyOtpFormContainer = () => {

    const [url] = useSearchParams();
    const navigate = useNavigate()

    const { loading, error, setErrorMsg, reset, startLoading, stopLoading } = useLoadingError();
    const [verifyOtp] = useVerfifyOtpMutation();

    const [otp, setOtp] = useState("");
    const token = url?.get('token');



    const handleSubmit = async (e: React.FormEvent) => {
        // try {
        e.preventDefault()
        if (otp?.length < 6) {
            return setErrorMsg("Please enter OTP")
        }
        startLoading();
        verifyOtp({
            code: otp,
            token
        }).unwrap().then(() => {
            navigate(`/reset-password?token=${token}`)
        }).catch((err) => {
            setErrorMsg(err?.data?.message)
        }).finally(() => {
            stopLoading()
        })
    }
    return (
        <>
            <VerifyOtpForm error={error} resetError={reset} loading={loading} handleSubmit={handleSubmit} otp={otp} setOtp={setOtp} />
        </>
    )
}

export default VerifyOtpFormContainer