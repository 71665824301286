import React from "react";
import { ICustomTabs } from "./custom-tabs.types";

const CustomTabsComponent = ({
  activeTab,
  handleTabsChange,
  tabs,
}: ICustomTabs) => {
  return (
    <>
      {tabs.map((tab: any, i: number) => {
        return (
          <button
            id={`tab-${i}`}
            className={`px-16 py-11 text-16 font-semibold leading-24 ${
              i === activeTab
                ? "border-b-2 border-white-heading text-white-heading"
                : "border-b-2 border-b-transparent text-white-para"
            }`}
            key={i}
            onClick={() => handleTabsChange(undefined, i)}
          >
            {tab.title}
          </button>
        );
      })}
    </>
  );
};

export default CustomTabsComponent;
